import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';


interface AlertDialogProps extends WithTranslation {
    open:boolean;
    title:string;
    message:string;
    handleClose: (ok:boolean) => void;
}

class AlertDialog extends React.Component<AlertDialogProps> {

    public render () {
        const {open, title, message, handleClose, t} = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="outlined" onClick={() => handleClose (false)}>{t("NO")}</Button>
                <Button variant="outlined" onClick={() => handleClose (true)} autoFocus>
                    {t("YES")}
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
}



export default withTranslation()(AlertDialog);