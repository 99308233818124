import Decimal from "decimal.js";
import { decimal } from "./bigdecimal";

export function store<T> (value:T) : any {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string') {
            if (value.length > 0) {
                return value;
            }
        }
        else if (typeof value === 'boolean') {
            return value ? 1:0; 
        }
        else {
            return value;
        }
    }

    return undefined;
}

export function load<T> (value:T):any {
    let ret : any = '';

    if (typeof value !== 'undefined' && value != null) {
        ret = value;
    }

    return ret;
}

export function empty(str?:string) {
    return (str && typeof str === 'string' && str.length > 0) ? false:true;
}

export function valueOf<T> (value?:T) : string {
    if (typeof value !== 'undefined' && value != null) {
        if (typeof value === "object" && value instanceof Decimal) {
            return value.toString ();
        }
        else if (typeof value === "string") {
            return value as string;
        }
        else if (typeof value === "number") {
            return value.toString ();
        }
    }

    return '';
}

export function stringValue (value?:string|null) : string | undefined {
    if (!value) {
        return undefined;
    }

    return value;
}

export function decimalValue (value?:string|null) : Decimal | undefined {
    if (!value) {
        return undefined;
    }

    return decimal(value);
}



export function numberValue (value?: string) : number | undefined {
    if (!value || !value.match(/^[\d|.]+$/gm)) {
        return undefined;
    }

    return !value.includes('.') ? parseInt (value, 10):parseFloat(value);
}


export function today () {
    const value = new Date ();

    return new Date (value.getFullYear (), value.getMonth (), value.getDate ());

}