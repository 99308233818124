import Decimal from 'decimal.js';


export const d0 = new Decimal (0);
export const d100 = new Decimal (100); 
export const d1 = new Decimal (1);

export function decimal (value?:string|number|Decimal):Decimal {
    if (typeof value !== 'undefined' && value !== null) {
        if ((typeof value === 'string' && value.length > 0) || typeof value == 'number') {
            return new Decimal (value);
        }
        else if (value instanceof Decimal) {
            return value;
        }
    }
    return d0;
}

export function round (value?:string|number|Decimal, decimalPlaces?:number):Decimal {
    let result = d0;

    if (typeof value !== 'undefined' && value !== null) {
        if ((typeof value === 'string' && value.length > 0) || typeof value == 'number') {
            result = new Decimal (value);
        }
        else if (value instanceof Decimal) {
            result = value;
        }
        if (decimalPlaces) {
            result = new Decimal (result.toFixed (decimalPlaces, Decimal.ROUND_HALF_UP));
        }
    }
    return result;
}
