import React from 'react';
import { TableProps, TableColumn } from 'react-data-table-component';
import { TablePagination, Checkbox, Box, styled, TableHead, TableFooter, Table, TableBody, TableRow, TableCell } from '@mui/material';
import TablePaginationActions from './TablePaginationActions';


export const EditTableCell = styled(TableCell)({
    padding:'.3rem',
    fontSize:'1rem',
    boxSizing: 'border-box'
});

export const EditTableCellBox = styled(Box)`
    overflow: clip;
    text-overflow: ellipsis;
    white-space:wrap;
    font-size:1rem;
    font-family:'Roboto', 'Amiri', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color:black;
`;
interface EditTableColumn<T> extends TableColumn<T> {
    disabled?: boolean;
    editCell?: (row: T, rowIndex: number, column: TableColumn<T>, id: string | number) => React.ReactNode;
}

interface EditTableProps<T> {
    columns: EditTableColumn<T>
    id?: string;
    footers?:React.ReactNode[];
    page?: number;
}

interface EditTableState<T> {
    row?: T;
    index?: number;
    rowsPerPage: number;
    page: number;
    selectedRows: T[];
}


class EditTable<T> extends React.Component<EditTableProps<T> & TableProps<T>, EditTableState<T>> {

    private ref = React.createRef<HTMLTableElement>();
    private editRef = React.createRef<HTMLTableCellElement> ();

    constructor(props: EditTableProps<T> & TableProps<T>) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            selectedRows: []
        }
    }

    private recalculatePage (page: number) {
        const {data} = this.props;
        const {rowsPerPage} = this.state;
        let currentPage = page;

        if (data.length === 0 || rowsPerPage === 0) {
            currentPage = 0;
        }
        else {
            currentPage = Math.min (currentPage, Math.floor((data.length - 1) / rowsPerPage));
        }

        return currentPage;
    }

    public componentDidMount(): void {
        const {page} = this.props;

        if (page) {
            this.setState ({
                page:page
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<EditTableProps<T> & TableProps<T>>): void {
        if (prevProps.data !== this.props.data) {
            const currentPage = this.recalculatePage (this.props.page ?? this.state.page);

            if (currentPage !== this.state.page) {
                this.setState ({
                    page: currentPage
                }, () => {
                    const {onChangePage, data} = this.props;

                    if (onChangePage) {
                        onChangePage (this.state.page, data.length);
                    }
                });
            }
        }
    }

    private handleOnFocus(ev: React.FocusEvent, row: T, index: number) {
        this.setState({
            row: row,
            index: index
        }, () => {
            const matches = ev.target.querySelectorAll<HTMLElement>('button, input, textarea, [tabindex="0"]');

            if (matches.length > 0) {
                matches.item(0).focus();
            }
        });
    }

    private handleOnBlur(ev: React.FocusEvent) {
        const relatedTarget = ev.relatedTarget?.closest (".MuiPickersPopper-root" );

        if (this.ref.current && !this.ref.current.contains(ev.relatedTarget) && !relatedTarget) {
            this.setState({
                row: undefined,
                index: undefined
            });
        }
    }

    public getCurrentEdit() {
        return ({
            row: this.state.row,
            index: this.state.index
        })
    }

    public setCurrentEdit(row: T, index: number) {
        const {data} = this.props;
        const rowIndex = data.indexOf (row);

        if (rowIndex !== -1) {
            const {rowsPerPage} = this.state;
            const page = rowsPerPage > 0 ? Math.floor (rowIndex / rowsPerPage):0;

            this.setState({
                row: row,
                index: index,
                page: page
            }, () => {
                this.editRef.current?.focus ();
            });
        }
    }

    private handleOnSelectRowsChange(row: T, checked: boolean) {
        const { onSelectedRowsChange, selectableRowsSingle, data } = this.props;

        if (selectableRowsSingle) {
            this.setState({
                selectedRows: checked ? [row] : []
            }, () => {
                const { selectedRows } = this.state;

                if (onSelectedRowsChange) {
                    onSelectedRowsChange({
                        allSelected: data && data.length === selectedRows.length ? true : false,
                        selectedCount: selectedRows.length,
                        selectedRows: selectedRows
                    });
                }
            });
        }
        else {
            this.setState((prev) => {
                const newSelectedRows = [...prev.selectedRows];

                if (checked) {
                    newSelectedRows.push(row)
                }
                else {
                    const index = newSelectedRows.indexOf(row);
                    if (index !== -1) {
                        newSelectedRows.splice(index, 1);
                    }
                }

                return {
                    selectedRows: newSelectedRows
                }
            }, () => {
                if (onSelectedRowsChange) {
                    onSelectedRowsChange({
                        allSelected: data && data.length === this.state.selectedRows.length ? true : false,
                        selectedCount: this.state.selectedRows.length,
                        selectedRows: this.state.selectedRows
                    });
                }
            });

        }
    }

    private handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.setState ({
            page: newPage
        }, () => {
            const {onChangePage, data} = this.props;

            if (onChangePage) {
                onChangePage (this.state.page, data.length);
            }
        });
    };
    
    private handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState ({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        });
    }

    public render() {
        const { columns, noHeader, data, id, noDataComponent, pagination,
            paginationComponentOptions, selectableRows, noTableHead, footers, disabled } = this.props;
        const { rowsPerPage } = this.state;
        const page = this.recalculatePage (this.state.page);
        const start = page * rowsPerPage, end = Math.min(data ? data.length : 0, start + rowsPerPage);
        const lastPage = end !== data.length ? false:true;
        const tableFooter = footers && footers.length > 0 ? footers:[];
        
        return (
            (data && data.length > 0 && (
                <Table key={`${id}-table`} padding='none' ref={this.ref} sx={{ tableLayout: 'fixed' }}>
                    {
                        (!noHeader && !noTableHead && (
                            <TableHead key={`${id}-table-head`} sx={{ backgroundColor: '#e6eefc' }}>
                                <TableRow key={`${id}-table-head-row`}>
                                    {
                                        (columns && (columns.map((column, index) => (
                                            <EditTableCell key={`${id}-table-head-cell-${index}`} sx={{width:column.width, maxWidth:column.maxWidth, fontWeight:'bold'}}>
                                                <EditTableCellBox sx={{textAlign:(column.right ? 'right': (column.center ? 'center':'start'))}}>
                                                {column.name}
                                                </EditTableCellBox>
                                            </EditTableCell>
                                        ))))
                                    }
                                </TableRow>
                            </TableHead>
                        ))
                    }
                    <TableBody key={`${id}-table-body`}>
                        {
                            (data && data.slice (start, end).map((row, rowIndex) => (
                                <TableRow key={`${id}-table-row-${rowIndex}`}>
                                    {
                                        (selectableRows && (
                                            <EditTableCell key={`${id}-table-cell-${rowIndex}-${0}`} sx={{ width: '38px'}}>
                                                {
                                                    <Checkbox checked={this.state.selectedRows.includes(row)} onChange={(event, checked) => this.handleOnSelectRowsChange(row, checked)} />
                                                }
                                            </EditTableCell>
                                        ))
                                    }
                                    {
                                        (columns && (columns.map((column: EditTableColumn<T>, index: number) => (
                                            <EditTableCell key={`${id}-table-cell-${rowIndex}-${index}`} 
                                                tabIndex={(!column.editCell || (this.state.row === row && this.state.index === index)) ? -1 : 0}
                                                ref={(this.state.row === row && this.state.index === index) ? this.editRef : undefined}
                                                 onFocus={(ev) => this.handleOnFocus(ev, row, index)} onBlur={(ev) => this.handleOnBlur(ev)}>
                                                    <EditTableCellBox sx={{textAlign:(column.right ? 'right': (column.center ? 'center':'start'))}}>
                                                {
                                                    ((this.state.row === row && this.state.index === index && column.editCell && !column.disabled && !disabled &&
                                                        column.editCell(row, index, column, 'id')
                                                    )
                                                        ||
                                                        (column.cell && column.cell(row, index, column, `id`))
                                                        ||
                                                        (column.format &&
                                                            <span>
                                                                {
                                                                    column.format(row, index)
                                                                }
                                                            </span>
                                                        )
                                                        ||
                                                        (column.selector &&
                                                            <span>
                                                                {
                                                                    column.selector(row, index)
                                                                }
                                                            </span>
                                                        )
                                                    )
                                                }
                                                </EditTableCellBox>
                                            </EditTableCell>
                                        ))))
                                    }
                                </TableRow>
                            )))
                        }
                    </TableBody>
                    {
                        ((pagination || (tableFooter && tableFooter.length > 0 && lastPage)) && (
                            <TableFooter>
                                {
                                    (tableFooter && tableFooter.length > 0 && lastPage && 
                                        tableFooter.map ((footer) => (
                                            footer
                                        ))
                                    )
                                }
                                {
                                    (pagination && 
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30, { label: paginationComponentOptions?.selectAllRowsItemText ?? 'All', value: -1 }]}
                                        colSpan={columns.length + (selectableRows ? 1 : 0)}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage={paginationComponentOptions?.rowsPerPageText ?? 'Rows Per Page'}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                      
                                    />
                                </TableRow>
                                    )
                                }
                            </TableFooter>
                        ))
                    }

                </Table>
            ) ||
                (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        {noDataComponent}
                    </Box>
                )
            )
        )
    }
}

export default EditTable;