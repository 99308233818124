export const validEmail = (email?:string) : boolean => {
    let result = false;

    if (typeof email !== 'undefined' && email !== null && email.length > 0) {
        result = email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ? true:false;
    }

    return result;
}

export const validPhone = (phone?:string) : boolean => {
    let result = false;

    if (typeof phone !== 'undefined' && phone !== null && phone.length > 0) {
        result = phone.match(
            /^(\+\d{2}\s)?(\d{3}\s)?(\d{3})?([ |-])?(\d{4})$/g
        ) ? true:false;
    }

    return result;
}