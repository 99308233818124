import {format} from 'date-fns';
import currency from 'currency.js'
import { Media } from '../service/api';
import Decimal from 'decimal.js';
import {d100} from './bigdecimal';

export function list (keys:string[], t:(k:string, options?:any) => string) : string {
    let fields = '';

    keys.forEach ((key, index) => {
        if (index > 0) {
            if (index === keys.length -1) {
                fields += t(" and ");
            }
            else {
                fields += t(", ");
            }
        }

        fields += "'" + t(key) + "'";
    });

    return fields;
}


export function missing (fields:string[], t:(k:string, options?:any) => string) {
    
    if (fields.length > 1) {
        return t("The fields {{fields}} are required", {fields:list(fields, t)});
    }
    else {
        return t("The field {{fields}} is required", {fields:fields[0]});
    }
}


export function date (value:string|Date):Date {
    let ret = new Date ();

    if (typeof value !== 'undefined' && value != null) {
        ret = new Date (value);
    }

    return ret;
}

export function pad (value:number, size:number):string {
    return ('0000'+value).slice(-size);
}

export function isoDate (value?:Date|string) {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string') {
            value = new Date (value);
        }
        if (value instanceof Date) {
            const year = pad (value.getFullYear (), 4);
            const month = pad (value.getMonth () + 1, 2);
            const day = pad (value.getDate (), 2);
            
            return `${year}-${month}-${day}T00:00:00.000`;
        }
        else {
            return value;
        }
    }
    else {
        return value;
    }
}


export function isoDateTime (value?:Date|string) {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string') {
            value = new Date (value);
        }
        if (value instanceof Date) {
            const year = pad (value.getFullYear (), 4);
            const month = pad (value.getMonth () + 1, 2);
            const day = pad (value.getDate (), 2);
            const hour = pad (value.getHours (), 2);
            const minute = pad (value.getMinutes (), 2);
            const second = pad (value.getSeconds (), 2);
            
            return `${year}-${month}-${day}T${hour}:${minute}:${second}.000`;        }
        else {
            return value;
        }
    }
    else {
        return value;
    }
}

export function dateOf (value?:Date) {
    if (typeof value !== 'undefined') {
        if (value instanceof Date) {
            return new Date (value.getFullYear (), value.getMonth (), value.getDate ());
        }
    }
    return value;
}


export function floor (value:number, decimal:number) {
    return Math.floor (value * Math.pow(10, decimal) / Math.pow(10, decimal));
}


export function formatDate (value?:Date|string) {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string' && value.length > 0) {
            return format (date(value), "dd/MM/yyyy");
        }
        else if (value instanceof Date) {
            return format(value, "dd/MM/yyyy");
        }
    }
    return '';
}

export function formatDateTime (value?:Date|string) {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string' && value.length > 0) {
            return format (date(value), "dd/MM/yyyy hh:mm a");
        }
        else if (value instanceof Date) {
            return format(value, "dd/MM/yyyy hh:mm a");
        }
    }
    return '';

}

export function formatTime (value?:Date|string) {
    if (typeof value !== 'undefined') {
        if (typeof value === 'string' && value.length > 0) {
            return format (date(value), "hh:mm a");
        }
        else if (value instanceof Date) {
            return format(value, "hh:mm a");
        }
    }
    return '';

}

export function formatPercentage (num:number|Decimal|undefined|null, fractions:number, locale:string) {
    if (typeof num === "undefined" || num === null) {
        return '';
    }
    else if (num instanceof Decimal) {
        return num.dividedBy (d100).toNumber().toLocaleString(locale,{style: 'percent', minimumFractionDigits:fractions});
    }
    else {
        return  Number(num/100).toLocaleString(locale,{style: 'percent', minimumFractionDigits:fractions}); 
    }
 }
 
 export function formatNumber (value:number|string|Decimal|undefined, options:currency.Options) {
    const opts = options ? {...options, symbol:''}:{symbol:''};

     if (typeof value !== 'undefined' && value !== null) {
        if (value instanceof Decimal) {
            return currency(value.toString (), opts).format ();
        }
        else {
            return currency(value, opts).format();
        }
    }
     else {
        return currency(0, opts).format();
    }
 }
 
 export function formatCurrency (amount:number|string|Decimal|undefined, options:currency.Options) {
     if (typeof amount !== 'undefined' && amount !== null) {
        if (amount instanceof Decimal) {
            return currency(amount.toString (), options).format ();
        }
        else { 
            return currency(amount, options).format();
        }
     }
     else {
         return currency(0, options).format();
     }
 }


 export function mediaUrl (row:Media, path?:string) : string {
    if (row.media_id && row.mime_type && row.mime_type.startsWith('image/')) {
        return `/api/1/media${path ?? ''}?id=${row.media_id}`;
    }
    else {
        return '/images/attach.svg';
    }
}

