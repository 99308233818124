import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { withTheme, WithTheme } from '@mui/styles';
import { Link, TableRow, TableCell, TextField, MenuItem, FormControl, InputLabel, Select, SelectChangeEvent, Divider, Box, Theme, Typography, Toolbar, Button, Stack } from "@mui/material";
import EditTable, { EditTableCell, EditTableCellBox } from '../components/EditTable';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import { AppContext } from '../AppContext';
import { dataTableStyles, customSort } from '../common/datatable';
import NoDataComponent from "../components/NoDataComponent";
import { withRouter, WithRouter } from "../common/router";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { FindChequesDetailsData, findCheques, ChequesDetailsTrxData, SearchRangeType } from "../service/api";
import BreadcrumbPath from "../components/BreadcrumbPath";
import { valueOf } from "../common/data";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ContainerBox, PageStack } from "../components/styled";
import { formatDate, formatNumber } from '../common/formats';
import { d0, decimal } from '../common/bigdecimal';
import Decimal from 'decimal.js';
import { LightTooltip } from '../components/styled';


export interface ChequesDetailsViewLocationState {
    cheque_id?: number;
    breadcrumbs?: string[];

}

interface ChequesDetailsViewsProps extends WithTheme<Theme>, WithRouter<ChequesDetailsViewLocationState>, WithTranslation {

}

interface ChequesDetailsViewsState {
    rowsPerPage: number;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_id?: number;
    cash_bank_id?: number;
    cashbox_name?: string;
    trx_id?: number;
    receive_cheque_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_Type?: number;
    amount?: Decimal;
    searchRange: SearchRangeType;
    search?: string;
    from_date?: Date;
    to_date?: Date;
    total_amount?: Decimal;
    cheques?: FindChequesDetailsData[];
}



type SavedChequesDetailsViewsState = Pick<ChequesDetailsViewsState, "searchRange" | "search" | "rowsPerPage" | "from_date" | "to_date" | "cheque_id" | "cheque_number">;

type DateType = "from_date" | "to_date";
class ChequesDetailsViews extends React.Component<ChequesDetailsViewsProps, ChequesDetailsViewsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    private columns = () => {
        const { t } = this.props;

        return ([
            {
                name: t("Trx Type Name"),
                selector: (row: FindChequesDetailsData) => this.handleTrxTypeName(row.trx_type),
                sortable: true,
                wrap: true

            },
            {
                name: t("Cheque Number"),
                selector: (row: FindChequesDetailsData) => row.cheque_number ? row.cheque_number : '',
                sortable: true,
                wrap: true

            }, {
                name: t("Trx Number"),
                selector: (row: FindChequesDetailsData) => row.trx_no ? row.trx_no : '',
                sortable: true,
                wrap: true,
                cell: (row: FindChequesDetailsData) => (
                    <LightTooltip title={row.trx_id} placement="top" arrow>
                        <Link underline="hover" color="primary" sx={{ cursor: "pointer" }} onClick={() => this.handleTrx(row)}>
                            {decimal(row.trx_no).toFixed()}
                        </Link>
                    </LightTooltip>
                )

            },

            {
                name: t('Date'),
                selector: (row: FindChequesDetailsData) => formatDate(row.trx_date),
                sortable: true
            },
            {
                name: t('Amount'),
                selector: (row: FindChequesDetailsData) => formatNumber(row.amount, { symbol: '', precision: 2 }),
                width: '17ch',
                right: true,


            },
        ]);
    };



    constructor(props: ChequesDetailsViewsProps) {
        super(props);

        this.state = {
            search: '',
            from_date: new Date(new Date().getFullYear(), 0, 1),
            to_date: new Date(),
            searchRange: SearchRangeType.LAST_3_MONTH,
            cheques: [],
            rowsPerPage: 20
        };
    }


    private findCheques = (cheque_id: number) => {
        if (cheque_id) {
            const app = this.context;

            const data = {
                cheque_id: cheque_id,
                searchRange: this.state.searchRange,
                rowsPerPage: this.state.rowsPerPage,
                from_date: this.state.from_date,
                to_date: this.state.to_date,
                today: new Date(),
                lang: app.lang
            };

            app.showProgress(true, () => {
                findCheques(data).then((result) => {
                    app.showProgress(false, () => {
                        const { total_amount } = this.calculateTotal(result.cheque_details);

                        this.setState({
                            cheque_number: result.cheque_number,
                            cheque_date: result.cheque_date,
                            trx_id: result.trx_id,
                            trx_no: result.trx_no,
                            trx_date: result.trx_date,
                            trx_Type: result.trx_type,
                            total_amount: total_amount,
                            cheques: result.cheque_details,
                        });
                    });
                }).catch((err) => {
                    app.showMessage({
                        severity: "error",
                        message: err.message,
                        progress: false
                    });
                });
            });
        }
    }

    public componentDidMount() {
        const app = this.context;
        const { location } = this.props;

        if (location && location.state && location.state.cheque_id) {
            this.setState({
                cheque_id: location.state.cheque_id,
            }, () => {
                if (this.state.cheque_id) {
                    this.findCheques(this.state.cheque_id);
                }
            });
        }
        else {
            const saved = app.findState<SavedChequesDetailsViewsState>(location.pathname);

            if (saved) {
                this.setState({
                    cheque_id: saved.cheque_id,
                    cheque_number: saved.cheque_number,
                    searchRange: saved.searchRange,
                    rowsPerPage: saved.rowsPerPage,
                    from_date: saved.from_date,
                    to_date: saved.to_date

                }, () => {
                    if (this.state.cheque_id) {
                        this.findCheques(this.state.cheque_id);
                    }
                });
            }
        }
    }

    public componentWillUnmount() {
        const app = this.context;
        const { location } = this.props;

        app.saveState(location.pathname, {
            cheque_id: this.state.cheque_id,
            cheque_number: this.state.cheque_number,
            search: this.state.search,
            searchRange: this.state.searchRange,
            rowsPerPage: this.state.rowsPerPage,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        });
    }

    private calculateTotal = (details?: ChequesDetailsTrxData[]) => {
        let total_amount = d0;

        if (details) {
            for (const detail of details) {
                total_amount = total_amount.plus(decimal(detail.amount));
            }
        }

        return { total_amount };
    }

    private handleChangeRowsPerPage = (currentRowsPerPage: number) => {
        this.setState({
            rowsPerPage: currentRowsPerPage
        });
    }

    private handlePrint = () => {
        const app = this.context;

        app.handleNavigate("/printcheques", { state: { clear: true } });
    }


    private handleSearchType = (event: SelectChangeEvent<string>) => {
        this.setState({
            searchRange: parseInt(event.target.value)
        }, () => {
            if (this.state.cheque_id) {
                this.findCheques(this.state.cheque_id);
            }
        });
    };

    private handleDate = (date: Date | null | undefined, field: DateType) => {
        const newState: Pick<ChequesDetailsViewsState, DateType> = {
            [field]: date
        };

        this.setState(newState,
            () => {
                if (this.state.cheque_id) {
                    this.findCheques(this.state.cheque_id);
                }
            });
    };

    private handleTrxTypeName(type?: number): string {
        const { t } = this.props;

        if (type !== undefined) {
            switch (type) {
                case 1:
                    return t("Receive Cheques");
                case 2:
                    return t("Payout Cheques To Bank");
                case 3:
                    return t("Received in Bank");
                case 4:
                    return t("Debit Note");
            }
        }
        return '';
    }
    private footers() {

        return ([
            (<TableRow key={`journal-details-footer`} sx={{ backgroundColor: '#eef3fc' }}>
                <EditTableCell colSpan={3}>
                    <EditTableCellBox sx={{ fontWeight: '500' }}>
                        <span> </span>
                    </EditTableCellBox>
                </EditTableCell>
                <TableCell sx={{ textAlign: 'right', fontWeight: '500' }}>
                </TableCell>
                <TableCell sx={{ textAlign: 'right', fontWeight: '500' }}>
                </TableCell>
            </TableRow>)
        ]);
    }

    
  

    private handleTrx = (row?: ChequesDetailsTrxData) => {
        const app = this.context;

        if (row?.cheque_id) {
            if (row.trx_type === 1) {
                // const newState: Receive_CheckLocationState = { receive_cheque_id: row.trx_id };
                   app.handleNavigate("/receive_cheque", { state: { receive_cheque_id: row.trx_id, breadcrumbs: ["/home", "/cheques", "/chequesdetailsview"] } });
                //  app.handleNavigate("/receive_cheque", { state: newState, breadcrumbs: ["/home", "/cheques", "/chequesdetailsview"] } );
            }
            else if(row.trx_type === 2){
                app.handleNavigate("/payout_cheque", { state: { payout_cheque_id: row.trx_id, breadcrumbs: ["/home", "/cheques", "/chequesdetailsview"]  } });
            }
            else if(row.trx_type === 3){
                app.handleNavigate("/bank_receive_cheque", { state: { bank_receive_cheque_id: row.trx_id, breadcrumbs: ["/home", "/cheques", "/chequesdetailsview"]  } });
            }
            else {                
                app.handleNavigate("/debit_note", { state: { debit_note_id: row.trx_id, breadcrumbs: ["/home", "/cheques", "/chequesdetailsview"]  } });
            }
        }
      
    }


    private get breadcrumbs() {
        const { location } = this.props;

        if (location?.state?.breadcrumbs) {
            return [...location.state.breadcrumbs, "/chequesdetailsview"];
        }

        return ["/home", "/cheques", "/chequesdetailsview"];
    }

    public render() {
        const app = this.context;
        const { t, theme } = this.props;


        return (
            <ContainerBox sx={{ display: 'flex', justifyContent: 'flex-start', margin: '0px' }}>
                <PageStack direction="column">
                    <BreadcrumbPath crumbs={this.breadcrumbs} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2 }}>
                        <Stack direction="column" gap={1} sx={{ flexGrow: 1 }}>
                            <Box sx={{ fontSize: '1.2rem' }}>
                                <span>{t("cheque Number.:")}</span>
                                <span>{this.state.cheque_number}</span>
                            </Box>
                            <Box sx={{ fontSize: '1.2rem' }}>
                                <span>{t("Cheque Date.:")}</span>
                                <span>{this.state.cheque_date ? formatDate(this.state.cheque_date) : ''}</span>
                            </Box>
                            <Box sx={{ fontSize: '1.2rem' }}>
                            </Box>
                            <Divider sx={{ color: '#a0a0a0' }} />
                        </Stack>
                    </Box>

                    <Toolbar sx={{ gap: 1 }}>
                        <FormControl sx={{ minWidth: '150px' }}>
                            <InputLabel id="search-range-label">{t("Search Range")}</InputLabel>
                            <Select
                                labelId="search-range-label"
                                id="search-range"
                                label={t("Search Range")}
                                value={valueOf(this.state.searchRange)}
                                onChange={this.handleSearchType}
                            >
                                <MenuItem value={0}>{t("Last Day")}</MenuItem>
                                <MenuItem value={1}>{t("Last Week")}</MenuItem>
                                <MenuItem value={2}>{t("Last Month")}</MenuItem>
                                <MenuItem value={3}>{t("Last 3 Month")}</MenuItem>
                                <MenuItem value={4}>{t("Custom Range")}</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            (this.state.searchRange === 4 &&
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            label={t("From Date")}
                                            value={this.state.from_date}
                                            onChange={(date) => this.handleDate(date, 'from_date')}
                                            renderInput={(params) => <TextField required {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            label={t("To Date")}
                                            value={this.state.to_date}
                                            onChange={(date) => this.handleDate(date, 'to_date')}
                                            renderInput={(params) => <TextField required {...params} />}
                                        />
                                    </LocalizationProvider>
                                </>
                            )
                        }
                        <Typography component="div" sx={{ flexGrow: 1 }} />
                        <Button variant="outlined" color="primary" startIcon={<LocalPrintshopOutlinedIcon />} onClick={() => this.handlePrint()}>{t("PRINT")}</Button>
                    </Toolbar>
                    <EditTable key={`warehouse-view-${this.state.rowsPerPage}`} columns={this.columns()} data={this.state.cheques ?? []} dense striped pagination={true} paginationPerPage={this.state.rowsPerPage} onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        sortFunction={customSort} customStyles={dataTableStyles(theme)} noDataComponent={<NoDataComponent message={t("There are no records to display")} />}
                        paginationComponentOptions={{ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: t('All') }}
                        paginationIconFirstPage={app.lang !== 'ar' ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />} paginationIconLastPage={app.lang !== 'ar' ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />} paginationIconNext={app.lang !== 'ar' ? <NavigateNextOutlinedIcon /> : <NavigateBeforeOutlinedIcon />} paginationIconPrevious={app.lang !== 'ar' ? <NavigateBeforeOutlinedIcon /> : <NavigateNextOutlinedIcon />}
                        // footers={this.footers()}
                    />
                </PageStack>
            </ContainerBox>
        );
    }
}

export default withTranslation()(withTheme(withRouter(ChequesDetailsViews)));