import React from "react";
import { Typography } from "@mui/material";

interface NoDataComponentProps {
    message:string;
}

class NoDataComponent extends React.Component<NoDataComponentProps> {

    public render () {
        const {message} = this.props;

        return (
            <Typography>{message}</Typography>
        );
    }
}


export default NoDataComponent;