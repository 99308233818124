export type ErrorType = {[key:string]: boolean};

export enum ModulesEnum {
    HOME = "HOME",
    GENERAL_LEDGER = "GENERAL LEDGER",
    INVENTORY_MANAGEMENT = "INVENTORY MANAGEMENT",
    CASH_FLOW = "CASH FLOW",
    ACCOUNTS_RECEIVABLES = "ACCOUNTS RECEIVABLES",
    ACCOUNTS_PAYABLES = "ACCOUNTS PAYABLES",
    SYSTEM = "SYSTEM"
}