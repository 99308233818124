import React from 'react';
import {Box, SxProps} from '@mui/material';

interface TabPanelProps {
    index?:number;
    value?:number;
    name?:string;
    sx?:SxProps;
}

class TabPanel extends React.Component<TabPanelProps> {
    static a11yProps (id:string, index:number) {
      return {
        id: `${id}-tab-${index}`,
        'aria-controls': `${id}-tabpanel-${index}`,
      };
    }

    public render () {
        const { children, value, index, name, ...other } = this.props;

        return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`${name}-tabpanel-${index}`}
            aria-labelledby={`${name}-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
            </div>
        );
    }
}

export default TabPanel;