import React, { SyntheticEvent } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { withTheme, WithTheme } from '@mui/styles';
import { SelectChangeEvent, Theme, TextField, Link, IconButton, Switch, FormControlLabel, FormGroup, FormControl, InputLabel, Select, MenuItem, Box, Toolbar, Tabs, Tab, Typography, Card, CardContent, Stack} from "@mui/material";
import { AppContext } from '../AppContext';
//import {BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis, Tooltip, LabelList } from 'recharts';
import { withRouter, WithRouter } from "../common/router";
import BreadcrumbPath from "../components/BreadcrumbPath";
import TabPanel from '../components/TabPanel';
import DataTable from 'react-data-table-component';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import { dataTableStyles, customSort } from '../common/datatable';
import NoDataComponent from "../components/NoDataComponent";
import { removeReport, searchReports, RunStatus, SearchRangeType, SearchReportResult } from "../service/api";
import { formatDateTime, formatNumber } from '../common/formats';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import AlertDialog from '../components/AlertDialog';
import { valueOf } from "../common/data";
import { ContainerBox, PageStack } from "../components/styled";

interface HomeProps extends WithTheme<Theme>, WithRouter, WithTranslation {

}

interface HomeState {
    accounts: number;
    analysis: number;
    trials: number;

    confirm: boolean;
    pending?: boolean;
    success?: boolean;
    failed?: boolean;
    searchRange: SearchRangeType;
    search?: string;
    from_date?: Date;
    to_date?: Date;
    toggleSearch: boolean;
    reports: SearchReportResult[],
    selected: number,
    rowsPerPage: number

    tab: number;
}

type SwitchType = "pending" | "success" | "failed";
type DateType = "from_date" | "to_date";
type SavedHomeState = Pick<HomeState, "pending" | "success" | "failed" | "searchRange" | "search" | "rowsPerPage" | "from_date" | "to_date"|"tab">;

class Home extends React.Component<HomeProps, HomeState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    private columns = () => {
        const { t } = this.props;

        return ([
            {
                name: t("Name"),
                selector: (row: SearchReportResult) => row.report_run_name ?? '',
                sortable: true,
                wrap: true,
                cell: (row: SearchReportResult) => (
                    <Link underline="hover" color="primary" sx={{ cursor: "pointer" }} onClick={() => this.handleView(row)}>
                        {row.report_run_name}
                    </Link>)

            },
            {
                name: t('Date'),
                selector: (row: SearchReportResult) => formatDateTime(row.created),
                sortable: true
            },
            {
                name: t("Size"),
                selector: (row: SearchReportResult) => formatNumber(row.file_size, { precision: 0, symbol: '' }),
                right: true,
                width: `15ch`
            },
            {
                name: t('Status'),
                selector: (row: SearchReportResult) => this.runStatusName(row.run_status),
                width: `15ch`
            },
            {
                name: t("Message"),
                selector: (row: SearchReportResult) => row.error_message ?? '',
                wrap: true
            },
            {
                name: '',
                grow: 0,
                compact: true,
                cell: (row: SearchReportResult) => (<Stack direction="row">
                    <IconButton
                        color="inherit"
                        aria-label="Edit"
                        sx={{ visibility: row.run_status !== RunStatus.SUCCESS ? 'hidden' : 'visible' }}
                        onClick={() => this.handleView(row)}
                    >
                        <SearchOutlinedIcon />
                    </IconButton>
                    <Box  sx={{ visibility: row.run_status !== RunStatus.SUCCESS ? 'hidden' : 'visible' }}>
                        <a href={`/api/1/viewreport?id=${row.report_run_id}`} download={row.file_name}>
                            <IconButton
                                color="inherit"
                                aria-label="Edit"
                            >
                                <CloudDownloadOutlinedIcon />
                            </IconButton>
                        </a>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="Delete"
                        onClick={() => this.handleDelete(row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Stack>),

            }
        ]);
    };

    constructor(props: HomeProps) {
        super(props);

        this.state = {
            accounts: 0,
            analysis: 0,
            trials: 0,

            tab: 0,
            confirm: false,
            from_date: new Date(new Date().getFullYear(), 0, 1),
            to_date: undefined,
            pending: true,
            success: true,
            failed: false,
            searchRange: SearchRangeType.LAST_MONTH,
            toggleSearch: false,
            reports: [],
            selected: 0,
            rowsPerPage: 20
        };
    }

    public componentDidMount() {
        const app = this.context;
        const { location } = this.props;

        const saved = app.findState<SavedHomeState>(location.pathname);

        if (saved) {
            this.setState({
                tab: saved.tab,
                pending: saved.pending,
                success: saved.success,
                failed: saved.failed,
                searchRange: saved.searchRange,
                toggleSearch: app.toggleSearch,
                rowsPerPage: saved.rowsPerPage,
                from_date: saved.from_date,
                to_date: saved.to_date
            }, () => {
                app.handleToggleSearch(saved.search);
            });
        }
        else {
            this.searchReports();
        }
    }

    public componentWillUnmount() {
        const app = this.context;
        const { location } = this.props;

        app.saveState(location.pathname, {
            tab: this.state.tab,
            pending: this.state.pending,
            success: this.state.success,
            failed: this.state.failed,
            search: this.state.search,
            searchRange: this.state.searchRange,
            rowsPerPage: this.state.rowsPerPage,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        });
    }

    public componentDidUpdate() {
        const app = this.context;

        if (app.toggleSearch !== this.state.toggleSearch) {
            this.searchReports();
        }
    }

    private runStatusName(run_status?: RunStatus): string {
        const { t } = this.props;

        if (run_status !== undefined) {
            switch (run_status) {
                case RunStatus.PENDING:
                    return t("Pending");

                case RunStatus.SUCCESS:
                    return t("Success");

                case RunStatus.FAILED:
                    return t("Fail")
            }
        }
        return '';
    }

    private handleTabChange = (event: SyntheticEvent, newValue: number) => {
        this.setState({
            tab: newValue
        });
    };

    private handleDate = (date: Date | null | undefined, field: DateType) => {
        const newState: Pick<HomeState, DateType> = {
            [field]: date
        };

        this.setState(newState,
            () => {
                this.searchReports();
            });
    };

    private handleSwitch = (checked: boolean, field: SwitchType) => {
        const newState: Pick<HomeState, SwitchType> = {
            [field]: checked
        };

        this.setState(newState, this.searchReports);
    };

    private handleSearchType = (event: SelectChangeEvent<string>) => {
        this.setState({
            searchRange: parseInt(event.target.value)
        }, () => {
            this.searchReports();
        });
    };

    private handleChangeRowsPerPage = (currentRowsPerPage: number) => {
        this.setState({
            rowsPerPage: currentRowsPerPage
        });
    }

    private handleConfirmClose = (yes: boolean) => {
        this.setState({
            confirm: false
        }, () => {
            if (yes) {
                this.removeReport();
            }
        });
    };

    private removeReport = () => {
        const app = this.context;

        if (this.state.selected) {
            const data = {
                report_run_id: this.state.selected
            };

            app.showProgress(true, () => {
                removeReport(data).then(() => {
                    app.showProgress(false, () => {
                        this.setState({
                            selected: 0
                        }, this.searchReports);
                    });
                }).catch((err) => {
                    app.showMessage({
                        severity: "error",
                        message: err.message,
                        progress: false
                    });
                });
            });
        }
    };

    private handleView = (row?: SearchReportResult) => {
        const app = this.context;

        if (row) {
            app.handleNavigate("/view", {
                state: {
                    report_run_id: row.report_run_id,
                    breadcrumbs: ["/home"]
                }
            });
        }
    }


    private searchReports = () => {
        const app = this.context;

        this.setState({
            search: app.search,
            toggleSearch: app.toggleSearch
        },
            () => {
                const data = {
                    search: app.search,
                    pending: this.state.pending,
                    success: this.state.success,
                    failed: this.state.failed,
                    searchRange: this.state.searchRange,
                    from_date: this.state.from_date,
                    to_date: this.state.to_date ?? new Date (),
                    today: new Date(),
                    lang: app.lang
                }

                app.showProgress(true, () => {
                    searchReports(data).then((result) => {
                        app.showProgress(false, () => {
                            this.setState({
                                reports: result.reports,
                            });
                        });
                    }).catch((err) => {
                        app.showMessage({
                            severity: "error",
                            message: err.message,
                            progress: false
                        });
                    });
                });
            });
    }

    private handleDelete = (row: SearchReportResult) => {
        this.setState({
            confirm: true,
            selected: row.report_run_id
        });
    };


    public render() {
        const app = this.context;
        const { t, theme } = this.props;

        return (
            <ContainerBox sx={{ display: 'flex', justifyContent: 'flex-start', margin: '0px' }}>
                <PageStack direction="column" sx={{ minWidth: '800px', display: 'inline-flex' }}>
                    <BreadcrumbPath crumbs={["/home"]} />
                    <Stack direction="column" sx={{ minWidth: '800px', display: 'inline-flex' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={this.state.tab} onChange={this.handleTabChange} aria-label="basic tabs example">
                                    <Tab label={t("Dashboard")} {...TabPanel.a11yProps('home', 0)} />
                                    <Tab label={t("Saved Reports")} {...TabPanel.a11yProps('home', 1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={this.state.tab} index={0} name="employee-tab">
                                <Stack direction="row" spacing={5}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div" >
                                                {t("Accounts")}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5, fontSize: '36pt', textAlign: 'center' }} color="text.secondary">
                                                {this.state.accounts}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {t("Analysis")}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5, fontSize: '36pt', textAlign: 'center' }} color="text.secondary">
                                                {this.state.analysis}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {t("Trials")}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5, fontSize: '36pt', textAlign: 'center' }} color="text.secondary">
                                                {this.state.trials}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </TabPanel>
                            <TabPanel value={this.state.tab} index={1} name="tax-tab">
                                <Stack direction="column" gap={2}>
                                <Toolbar sx={{ gap: 2 }}>
                                    <FormControl sx={{ minWidth: '150px' }}>
                                        <InputLabel id="search-range-label">{t("Search Range")}</InputLabel>
                                        <Select
                                            labelId="search-range-label"
                                            id="search-range"
                                            label={t("Search Range")}
                                            value={valueOf(this.state.searchRange)}
                                            onChange={this.handleSearchType}
                                        >
                                            <MenuItem value={0}>{t("Last Day")}</MenuItem>
                                            <MenuItem value={1}>{t("Last Week")}</MenuItem>
                                            <MenuItem value={2}>{t("Last Month")}</MenuItem>
                                            <MenuItem value={3}>{t("Last 3 Month")}</MenuItem>
                                            <MenuItem value={4}>{t("Custom Range")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        (this.state.searchRange === 4 &&
                                            <>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        label={t("From Date")}
                                                        value={this.state.from_date}
                                                        onChange={(date) => this.handleDate(date, 'from_date')}
                                                        renderInput={(params) => <TextField required {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        label={t("To Date")}
                                                        value={this.state.to_date}
                                                        onChange={(date) => this.handleDate(date, 'to_date')}
                                                        renderInput={(params) => <TextField required {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </>
                                        )
                                    }
                                    <FormGroup sx={{ display: 'inline-block' }}>
                                        <FormControlLabel control={<Switch />}
                                            label={t("Success") as string}
                                            checked={this.state.success}
                                            onChange={(event, checked) => this.handleSwitch(checked, 'success')}
                                        />
                                    </FormGroup>
                                    <FormGroup sx={{ display: 'inline-block' }}>
                                        <FormControlLabel control={<Switch />}
                                            label={t("Pending") as string}
                                            checked={this.state.pending}
                                            onChange={(event, checked) => this.handleSwitch(checked, 'pending')}
                                        />
                                    </FormGroup>
                                    <FormGroup sx={{ display: 'inline-block' }}>
                                        <FormControlLabel control={<Switch />}
                                            label={t("Fail") as string}
                                            checked={this.state.failed}
                                            onChange={(event, checked) => this.handleSwitch(checked, 'failed')}
                                        />
                                    </FormGroup>
                                    <Typography component="div" sx={{ flexGrow: 1 }} />
                                </Toolbar>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                </Box>
                                <DataTable key={`journals-${this.state.rowsPerPage}`} columns={this.columns()} data={this.state.reports} dense striped pagination={true} paginationPerPage={this.state.rowsPerPage} onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    sortFunction={customSort} customStyles={dataTableStyles(theme)} noDataComponent={<NoDataComponent message={t("There are no records to display")} />}
                                    paginationComponentOptions={{ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: t('All') }}
                                    paginationIconFirstPage={app.lang !== 'ar' ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />} paginationIconLastPage={app.lang !== 'ar' ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />} paginationIconNext={app.lang !== 'ar' ? <NavigateNextOutlinedIcon /> : <NavigateBeforeOutlinedIcon />} paginationIconPrevious={app.lang !== 'ar' ? <NavigateBeforeOutlinedIcon /> : <NavigateNextOutlinedIcon />}
                                />
                                </Stack>
                            </TabPanel>
                        </Box>

                    </Stack>
                    {/* <Box sx={{ width: '100%', paddingTop:10, textAlign:'center' }}>
                        <BarChart width={800} height={400} data={this.state.students_per_grade} onClick={this.handlePvBarClick}>
                            <XAxis dataKey="grade_name"/>
                            <YAxis yAxisId="a" />
                            <Tooltip />
                            <CartesianGrid vertical={false} />
                            <Bar yAxisId="a" dataKey="student_count" onAnimationStart={this.handleBarAnimationStart} onAnimationEnd={this.handleBarAnimationEnd}>
                            <LabelList fill="#000" angle={-45} />
                            {
                                this.state.students_per_grade.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.state.colors[index % this.state.colors.length]} />
                                ))
                            }
                            </Bar>
                        </BarChart>
                    </Box> */}
                </PageStack>
                <AlertDialog open={this.state.confirm} handleClose={this.handleConfirmClose} title={t("Confirm Cancel")} message={t("Are you sure you want remove the selected report ?")} />
            </ContainerBox>
        );
    }
}

export default withTranslation()(withTheme(withRouter(Home)));