import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, Container, Stack, Paper, Grid, Button, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { ErrorType } from '../common/types';
import { FinancialYearData, selectOrganization } from '../service/api';
import { Logo } from '../components/Logo';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { AppContext } from '../AppContext';
import logoIcon from '../images/logo.svg';

interface SelectOrganizationState {
    organization_id?: number;
    errors: ErrorType;
}

class SelectOrganization extends React.Component<WithTranslation, SelectOrganizationState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            errors: {}
        };
    }

    public componentDidMount(): void {
        const app = this.context;
        
        this.setState ({
            organization_id: app.organization_id
        });
    }

    private handleListItemClick = (organization_id:number) => {
        this.setState ({
            organization_id: organization_id
        });
    };

    private selectFinancialYear(financial_year_id?: number, financial_years?: FinancialYearData[]) {

        if (financial_year_id) {
            return financial_year_id;
        }
        else if (financial_years && financial_years.length > 0) {
            return financial_years[0].financial_year_id;
        }
        return undefined;
    }

    private handleSelectOrganization = (organization_id?: number) => {
        const app = this.context;

        if (organization_id !== undefined) {
            const data = {
                organization_id: organization_id,
                lang: app.lang
            }

            app.showProgress(true, () => {
                selectOrganization (data).then (organization => {
                    app.setState({
                        progress: false,
                        organization_id: this.state.organization_id,
                        organization_name: organization.organization_name,
                        currency_code: organization?.currency_code,
                        currency_symbol: organization?.currency_symbol,
                        fraction_digits: organization?.fraction_digits,
                        gl_account_pattern: organization?.gl_account_pattern,
                        costcenter_pattern: organization?.costcenter_pattern,
                        financial_years: organization?.financial_years,
                        financial_year_id: this.selectFinancialYear(organization.financial_year_id, organization?.financial_years),
                        paper_size: organization.page_size === 'Custom' ? [organization.page_width ?? 850, organization.page_height ?? 110] : organization.page_size
                    }, () => {
                        if (organization_id === 0) {
                            app.handleNavigate ('/businessaccounts');
                        }
                        else {
                            app.handleNavigate('/home');
                        }
                    });
                }).catch((err) => {
                    app.showMessage({
                        severity: "error",
                        message: err.message,
                        progress: false
                    });

                    const errors: ErrorType = {};

                    if (err.refs && err.refs.length > 0) {
                        err.refs.forEach((ref: string) => {
                            errors[ref] = true;
                        });
                    }
                    this.setState({
                        errors: errors
                    });
                });
            });
        }
    };


    public render() {
        const {organizations} = this.context;
        const { t } = this.props;
        const {organization_id} = this.state;

        return (
            <Container sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <Stack direction="column" sx={{ display: 'inline-flex' }}>
                    <Paper elevation={3} sx={{ marginTop: '100px', marginBottom: '10px', padding: '20px', width: '450px', borderRadius: '10px' }}>
                        <Grid container spacing={3} direction="column"
                            justifyContent="center">
                            <Grid item>
                                <Logo src={logoIcon} />
                            </Grid>
                            <Grid item>
                                <Box sx={{
                                    width: '100%',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300,
                                    '& ul': { padding: 0 },
                                }}>
                                    <List component="nav" aria-label="Organizations">
                                        {
                                            (organizations && organizations.map ((organization, index) => (
                                                <ListItemButton key={`select-orgainization-${index}`}
                                                selected={organization_id === organization.organization_id}
                                                onClick={() => this.handleListItemClick(organization.organization_id)}
                                                onDoubleClick={() => this.handleSelectOrganization(organization.organization_id)}
                                            >
                                                <ListItemIcon>
                                                    <BusinessOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={organization.organization_name} />
                                            </ListItemButton>
        
                                            )))
                                        }
                                    </List>
                                </Box>
                            </Grid>
                            <Grid item sx={{ textAlign: 'right' }}>
                                <Button color="primary" variant="outlined" onClick={() => this.handleSelectOrganization(this.state.organization_id)} disabled={this.state.organization_id !== undefined ? false:true}>{t("Select")}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Stack>
            </Container>
        );
    }
}

export default withTranslation()(SelectOrganization);