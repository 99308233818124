import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccessibilityOutlinedIcon from '@mui/icons-material/AccessibilityOutlined';
import SouthAmericaOutlinedIcon from '@mui/icons-material/SouthAmericaOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import AttractionsOutlinedIcon from '@mui/icons-material/AttractionsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AdjustIcon from '@mui/icons-material/Adjust';
import InventoryIcon from '@mui/icons-material/Inventory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

export const breadcrumbIcon = (key: string) => {
    switch (key) {
        case "/home":
            return (<CottageOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/profile":
            return (<GroupOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/roles":
        case "/role":
            return (<AddTaskOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/users":
        case "/user":
            return (<GroupOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/currencies":
        case "/currency":
        case "/printcurrencies":
            return (<PaidOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/exchanges":
        case "/exchange":
            return (<CurrencyExchangeOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/view":
            return (<PictureAsPdfOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/countries":
        case "/country":
            return (<PublicOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/governerates":
        case "/governerate":
            return (<LocationOnOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/regions":
        case "/region":
            return (<SouthAmericaOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/taxes":
        case "/tax":
            return (<AutoStoriesOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/cities":
        case "/city":
            return (<LocationCityOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/businessaccounts":
        case "/businessaccount":
        case "/organization":
            return (<BusinessOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/branch":
            return (<AddLocationIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/activities":
        case "/activity":
            return (<AccessibilityOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/paymentterms":
        case "/paymentterm":
            return (<PaymentsOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/employee_types":
        case "/employee_type":
            return (<EmojiPeopleOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/employees":
        case "/employee":
            return (<BadgeIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/jobpositions":
        case "/jobposition":
            return (<AttributionOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/departments":
        case "/department":
            return (<ManageHistoryOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/assetdepreciation":
        case "/asset":
            return (<DirectionsBusFilledOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/subjournals":
        case "/subjournal":
            return (<AutoAwesomeMotionOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/deferredrevenues":
        case "/deferredrevenue":
            return (<ChromeReaderModeOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/deferredexpenses":
        case "/deferredexpense":
            return (<ExplicitOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/currenciesrevaluation":
        case "/currencyrevaluation":
            return (<CurrencyExchangeOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/closeperiod":
            return (<LockClockOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/vendors":
        case "/vendor":
            return (<LocalShippingOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/vendortypes":
        case "/vendortype":
            return (<TypeSpecimenOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/customers":
        case "/customer":
            return (<GroupsOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/customertypes":
        case "/customertype":
            return (<QueuePlayNextOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/salesteams":
        case "/salesteam":
            return (<Diversity1OutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/bankaccounts":
        case "/bankaccount":
            return (<AccountBalanceOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/cashboxes":
        case "/cashbox":
            return (<PriceChangeOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/unittypes":
        case "/unittype":
            return (<CategoryOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printunittypes":
        case "/printunittype":
            return (<CategoryOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/transactiontypes":
        case "/transactiontype":
            return (<ReceiptOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/warehousetypes":
        case "/warehousetype":
            return (<QueuePlayNextOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/warehouses":
        case "/warehouse":
            return (<CottageOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/categories":
        case "/category":
            return (<MarkUnreadChatAltOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/properties":
        case "/property":
            return (<AttractionsOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/products":
        case "/product":
        case "/productview":
            return (<QrCode2OutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/exchangerates":
        case "/exchangerate":
            return (<CurrencyExchangeOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/promotions":
        case "/promotion":
            return (<ReceiptOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/banks":
        case "/bank":
            return (<AccountBalanceIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/pricelists":
        case "/pricelist":
            return (<ReceiptOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/costlists":
        case "/costlist":
            return (<ReceiptOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/quotations":
        case "/quotation":
            return (<LocalOfferIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/salesorders":
        case "/sales_order":
            return (<BorderColorIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/salesinvoices":
        case "/salesinvoice":
            return (<ReceiptIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/purchase_orders":
        case "/purchase_order":
            return (<ShoppingCartIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/purchase_invoices":
        case "/purchase_invoice":
            return (<ReceiptIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/purchase_quotations":
        case "/purchase_quotation":
            return (<LocalOfferIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/sales_returns":
        case "/sales_return":
            return (<KeyboardReturnIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/purchase_returns":
        case "/purchase_return":
            return (<KeyboardReturnIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/delivery_invoices":
        case "/delivery_invoice":
            return (<LocalShippingIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/replenishments":
        case "/replenishment":
            return (<InventoryIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/issues":
        case "/issued":
            return (<LocalShippingIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/inventoryadjustments":
        case "/inventoryadjustment":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/adjust_costs":
        case "/adjust_cost":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/warehouseview":
            return (<WarehouseIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/warehouseproductview":
            return (<WarehouseIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/receive_cashs":
        case "/receive_cash_receipt":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/payout_cashs":
        case "/payout_cash_receipt":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/custodies":
        case "/custody":
            return (<CenterFocusWeakIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/expenses":
        case "/expense":
            return (<PaymentsIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/receive_cheques":
        case "/receive_cheque":
            return (<ReceiptLongIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/payout_cheques":
        case "/payout_cheque":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/custody_expenses":
        case "/custody_expense":
            return (<ReceiptLongIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/payout_cocheques":
        case "/payout_cocheque":
            return (<AdjustIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/debit_notes":
        case "/debit_note":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/credit_notes":
        case "/credit_note":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/collected_debit_cheques":
        case "/collected_debit_cheque":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/collected_credit_cheques":
        case "/collected_credit_cheque":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/bank_receive_cheques":
        case "/bank_receive_cheque":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/cheques":
        case "/chequesdetailsview":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/companycheques":
        case "/companychequesdetailsview":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/journals":
        case "/journal":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/analysiscodes":
        case "/analysiscode":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/bank_balances":
        case "/bank_balance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/cashbox_balances":
        case "/cashbox_balance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/customer_balances":
        case "/customer_balance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/vendor_balances":
        case "/vendor_balance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/custody_employee_balances":
        case "/custody_employee_balance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/accountbalances":
        case "/accountbalance":
            return (<AccountTreeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/costcenterbalances":
        case "/costcenterbalance":
            return (<AccountTreeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/accounts":
        case "/account":
            return (<AccountTreeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/costcenters":
        case "/costcenter":
            return (<AccountTreeIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/financialyears":
        case "/financialyear":
            return (<CalendarMonthIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/balancesheets":
        case "/balancesheet":
            return (<FormatAlignRightIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/incomestatements":
        case "/incomestatement":
            return (<FormatAlignRightIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printaccounts":
        case "/printaccount":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printaccountanalysis":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printaccountbalances":
        case "/printaccountbalance":

            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcostcenters":
        case "/printcostcenter":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/postjournals":
            return (<DriveFileRenameOutlineOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printanalysiscodes":
        case "/printanalysiscode":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printsubjournals":
        case "/printsubjournal":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printunit_types":
        case "/printunit_type":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printproducts":
        case "/printproduct":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printwarehouses":
        case "/printwarehouse":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printwarehousetypes":
        case "/printwarehousetype":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcategories":
        case "/printcategory":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printproperties":
        case "/printproperty":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printtransactiontypes":
        case "/printtransactiontype":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbankaccounts":
        case "/printbankaccount":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbanks":
        case "/printbank":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printexpenses":
        case "/printexpense":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/printcustomers":
        case "/printcustomer":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcustomertypes":
        case "/printcustomertype":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendors":
        case "/printvendor":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendortypes":
        case "/printvendortype":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpricelists":
        case "/printpricelist":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcostlists":
        case "/printcostlist":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpromotions":
        case "/printpromotion":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printdepartments":
        case "/printdepartment":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printemployees":
        case "/printemployee":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printemployeetypes":
        case "/printemployeetype":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printjobpositions":
        case "/printjobposition":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printtaxes":
        case "/printtax":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpaymentterms":
        case "/printpaymentterm":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printactivities":
        case "/printactivity":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printregions":
        case "/printregion":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcountries":
        case "/printcountrie":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/batches":
        case "/batch":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbatches":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printtrialbalance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printtrialbalances":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printaccountledger":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcostcenterledger":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcostcenterbalances":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/printcostcentertransactions":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbalancesheets":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printincomestatements":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printjournals":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printaccjournals":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/glreports":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/screports":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/cbreports":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/apreports":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);


        case "/printreceive_cash":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpayout_cash":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashtrxbalance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/customercreditnote":
            return (<NoteAltIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/customerdebitnotes":
        case "/customerdebitnote":
            return (<NoteAltIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/vendorcreditnotes":
        case "/vendorcreditnote":
            return (<NoteAltIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/vendordebitnotes":
        case "/vendordebitnote":
            return (<NoteAltIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/contracts":
        case "/contract":
            return (<AssignmentIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/work_orders":
        case "/work_order":
            return (<DeveloperBoardIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printitemcard":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printitemsbalancesvalues":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printitemsbalances":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printreplenishments":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printissues":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashreceive":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashpayout":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashcustody":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashcustodytrx":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printchequesreceive":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printchequespayout":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printchequescompanypayout":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbankreceivecheques":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printdebitnote":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcreditnote":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashboxbalance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcashboxes":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printchequestatus":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcompanychequestatus":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printcustodychequestatus":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printbankaccountbalance":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printtransactions":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printserials":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printexpiry_dates":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printproductbatches":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printquotations":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpurchaseorders":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpurchaseinvoices":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpurchasereturns":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printpurchaseanalysis":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendoranalysis":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendorstatement":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendordebitnotes":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printvendorcreditnotes":
            return (<EventNoteIcon sx={{ mr: .5 }} fontSize="inherit" />);

        default:
            return (<QuestionMarkOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

    }
};