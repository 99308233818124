
class ServiceError extends Error {
    status: number;
    severity:string;
    refs?:string[];

    constructor (status:number, msg:string, severity:string, refs?:string[]) {
        super (msg);

        this.status = status;
        this.severity = severity;
        this.refs = refs;
    }
}

export default ServiceError;
