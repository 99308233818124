import { Theme } from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { Buffer } from "buffer";
import {TreeRowData} from '../components/TreeDataTable';

export const dataTableStyles = (theme:Theme) => ({
    headRow: {
		style: {
			backgroundColor: theme.palette.primary.light,
		}
	},
});


export function customSort<T> (rows:T[], selector:(row:T) => any, direction:string) : T[] {
    return rows.sort((rowA, rowB) => {

        const aField = selector(rowA)
        const bField = selector(rowB)

        let comparison = 0;

        if (aField > bField) {
        comparison = 1;
        } else if (aField < bField) {
        comparison = -1;
        }

        return direction === 'desc' ? comparison * -1 : comparison;
    });
}

export function encodeCSV (data:string, delimiter:string) {
    if (data !== undefined) {
        if (data.includes('"') || data.includes (delimiter)) {
            return `"${data.replaceAll('"', '""')}"`;
        }
        return data;
    }
    return '';
}

export function convertToCSV<T>(data:T[], columns:TableColumn<T>[]) {
    let result = '';

    const delimiter = ',';
    const lineDelimiter = '\n';

    columns.forEach ((col:TableColumn<T>, index) => {
        if (index > 0) {
            result += delimiter;
        }
        result += encodeCSV(col.name as string ?? '' , delimiter);
    });

    result += lineDelimiter;

    data.forEach (row => {
        columns.forEach((col, index) => {
            if (index > 0) {
                result += delimiter;
            }
            const value = col.selector ? col.selector(row, index):'';

            if (value !== undefined) {
                result += encodeCSV(`${value}`, delimiter);
            }
        })
        result += lineDelimiter
    });

    const bom =  Buffer.from([0xef, 0xbb, 0xbf]);

    return `data:text/csv;utf-8,` + bom.toString () + encodeURI(result);
}


const delimiter = ',';
const lineDelimiter = '\n';

export function convertRowToCSV<T extends TreeRowData<T>>(row:T, columns:TableColumn<T>[]) {
    let result = '';

    columns.forEach((col, index) => {
        if (index > 0) {
            result += delimiter;
        }
        const value = col.selector ? col.selector(row, index):'';

        if (value !== undefined) {
            result += encodeCSV(`${value}`, delimiter);
        }
    })

    result += lineDelimiter;

    if (row.children) {
        row.children.forEach (row => {
            result += convertRowToCSV (row, columns);
        });
    }

    return result;
}


export function convertTreeToCSV<T extends TreeRowData<T>>(data:T[], columns:TableColumn<T>[]) {
    let result = '';

    columns.forEach ((col, index) => {
        if (index > 0) {
            result += delimiter;
        }
        result += encodeCSV(col.name as string ?? '' , delimiter);
    });

    result += lineDelimiter;

    data.forEach (row => {
        result += convertRowToCSV (row, columns);
    });

    const bom =  Buffer.from([0xef, 0xbb, 0xbf]);

    return `data:text/csv;utf-8,` + bom.toString () + encodeURI(result);
}



