import React from "react";
import { styled } from "@mui/material";
import { IconButton, Theme, Collapse, Drawer, Divider, List, ListItem, ListItemText, ListSubheader, ListItemIcon } from "@mui/material";
import {withTheme, WithTheme} from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from "../AppContext";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import SouthAmericaOutlinedIcon from '@mui/icons-material/SouthAmericaOutlined';
import AccessibilityOutlinedIcon from '@mui/icons-material/AccessibilityOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import AttractionsOutlinedIcon from '@mui/icons-material/AttractionsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdjustIcon from '@mui/icons-material/Adjust';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import NoteAltIcon from '@mui/icons-material/NoteAlt';


export const drawerWidth = 280;
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface Menu {
    key:string;
    icon:JSX.Element;
    sub?:Menu[];
}

const menus:{[key:string]: {[key:string]:Menu[]}} = {
    "GENERAL LEDGER":{
        "MAIN":[
            {
                key:'/journals',
                icon:<AutoAwesomeMotionOutlinedIcon/>
            },
            {
                key:'/accountbalances',
                icon:<AccountTreeIcon/>
            },        
            {
                key:'/costcenterbalances',
                icon:<AccountTreeIcon/>
            },        
            {
                key: '/deferredrevenues',
                icon: <ChromeReaderModeOutlinedIcon/>
            },
            {
                key: '/deferredexpenses',
            icon: <ExplicitOutlinedIcon/>
            },
            {
                key: '/assetdepreciation',
                icon: <DirectionsBusFilledOutlinedIcon/>
            },
            {
                key:'/currenciesrevaluation',
                icon:<CurrencyExchangeIcon/>
            },
            {
                key:'/financialyears',
                icon:<CalendarMonthIcon/>
            }
        ],
        "REPORTS":[
            {
                key:'/glreports',
                icon:<AccountBalanceOutlinedIcon/>
            },

        ],
        "SETUP":[
            {
                key: '/accounts',
                icon: <AccountTreeIcon/>
            },
            {
                key: '/costcenters',
                icon: <AccountTreeIcon/>
            },
            {
                key: '/analysiscodes',
                icon: <AccountTreeOutlinedIcon/>
            },
            {
                key: '/subjournals',
                icon: <AutoAwesomeMotionOutlinedIcon/>
            },
            {
                key: '/batches',
                icon: <AutoAwesomeMotionOutlinedIcon/>
            },
            {
                key: '/incomestatements',
                icon: <ListAltOutlinedIcon/>
            },
            {
                key: '/balancesheets',
                icon: <ListAltOutlinedIcon/>
            }
        ]
    },
    
    "INVENTORY MANAGEMENT":{
        MAIN: [
            {
                key:'/replenishments',
                icon:<InventoryIcon/>
            },
            {
                key:'/issues',
                icon:<LocalShippingIcon/>
            },
            {
                key:'/work_orders',
                icon:<LocalShippingIcon/>
            },
            {
                key:'/inventoryadjustments',
                icon:<AdjustIcon/>
            },
            {
                key:'/adjust_costs',
                icon:<AccountBalanceOutlinedIcon/>
            },
            {
                key: '/products',
                icon: <QrCode2OutlinedIcon/>
            },        
            {
                key: '/warehouses',
                icon: <CottageOutlinedIcon/>
            },
            {
                key: '/costlists',
                icon: <ReceiptOutlinedIcon/>
            },
      
        ],
        "REPORTS":[
            {
                key:'/screports',
                icon:<AccountBalanceOutlinedIcon/>
            }
        ],
        "SETUP":[
            {
                key: '/categories',
                icon: <MarkUnreadChatAltOutlinedIcon/>
            },
            {
                key: '/properties',
                icon: <AttractionsOutlinedIcon/>
            },
            {
                key: '/warehousetypes',
                icon: <QueuePlayNextOutlinedIcon/>
            },
            {
                key: '/transactiontypes',
                icon: <ReceiptOutlinedIcon/>
            },
            {
                key: '/unittypes',
                icon: <CategoryOutlinedIcon/>
            }
        ]
    },
    "CASH FLOW": {
        "MAIN": [
            {
                key:'/receive_cashs',
                icon:<AccountBalanceOutlinedIcon/>
            },
            {
                key:'/payout_cashs',
                icon:<AccountBalanceOutlinedIcon/>
            },
            {
                key:'/receive_cheques',
                icon:<ReceiptLongIcon/>
            },
            {
                key:'/payout_cocheques',
                icon:<ReceiptLongIcon/>
            },
            {
                key:'/payout_cheques',
                icon:<ReceiptLongIcon/>
            },
            {
                key:'/bank_receive_cheques',
                icon:<EventNoteIcon/>
            },
            {
                key:'/debit_notes',
                icon:<EventNoteIcon/>
            },
            {
                key:'/credit_notes',
                icon:<EventNoteIcon/>
            },
            {
                key:'/collected_debit_cheques',
                icon:<EventNoteIcon/>
            },
            {
                key:'/collected_credit_cheques',
                icon:<EventNoteIcon/>
            },
            {
                key:'/custody_expenses',
                icon:<ReceiptLongIcon/>
            },
            {
                key:'/cheques',
                icon:<EventNoteIcon/>
            },
            {
                key:'/companycheques',
                icon:<EventNoteIcon/>
            },
            {
                key: '/bank_balances',
                icon: <AccountBalanceIcon/>
            },
            {
                key: '/cashbox_balances',
                icon: <AccountBalanceIcon/>
            },
            {
                key: '/custody_employee_balances',
                icon: <AccountBalanceIcon/>
            },

        ],
        "REPORTS": [
            {
                key:'/cbreports',
                icon:<AccountBalanceOutlinedIcon/>
            },
        ],
        "SETUP": [
            {
                key: '/bankaccounts',
                icon: <AccountBalanceOutlinedIcon/>
            },
            {
                key: '/cashboxes',
                icon: <PriceChangeOutlinedIcon/>
            },
            {
                key: '/banks',
                icon: <AccountBalanceIcon/>
            },            
            {
                key: '/expenses',
                icon: <PaymentsIcon/>
            }            
        ]
    },
    "ACCOUNTS RECEIVABLES": {
        "MAIN": [
            {
                key:'/quotations',
                icon:<LocalOfferIcon/>
            },
            {
                key:'/salesorders',
                icon:<BorderColorIcon/>
            },
            {
                key:'/salesinvoices',
                icon:<ReceiptIcon/>
            },        
            {
                key:'/delivery_invoices',
                icon:<LocalShippingIcon/>
            },        
            {
                key:'/sales_returns',
                icon:<KeyboardReturnIcon/>
            },        
            {
                key:'/customercreditnotes',
                icon:<NoteAltIcon/>
            },        
            {
                key:'/customerdebitnotes',
                icon:<NoteAltIcon/>
            },        
            {
                key: '/customer_balances',
                icon: <AccountBalanceIcon/>
            },
        ],
        "REPORTS": [
            {
                key:'/arreports',
                icon:<AccountBalanceOutlinedIcon/>
            }            
        ],
        "SETUP": [
            {
                key: '/customers',
                icon: <GroupsOutlinedIcon/>
            },
            {
                key: '/salesteams',
                icon: <Diversity1OutlinedIcon/>
            },
            {
                key: '/pricelists',
                icon: <ReceiptOutlinedIcon/>
            },
            {
                key: '/promotions',
                icon: <ReceiptOutlinedIcon/>
            },
            {
                key: '/customertypes',
                icon: <QueuePlayNextOutlinedIcon/>
            }
        ]
    },
    "ACCOUNTS PAYABLES": {
        "MAIN": [
            {
                key:'/purchase_quotations',
                icon:<AccountBalanceOutlinedIcon/>
            },        
            {
                key:'/purchase_orders',
                icon:<ShoppingCartIcon/>
            },
            {
                key:'/purchase_invoices',
                icon:<ReceiptIcon/>
            },        
            {
                key:'/purchase_returns',
                icon:<KeyboardReturnIcon/>
            },        
            {
                key:'/vendorcreditnotes',
                icon:<NoteAltIcon/>
            },        
            {
                key:'/vendordebitnotes',
                icon:<NoteAltIcon/>
            },        
            {
                key: '/vendor_balances',
                icon: <AccountBalanceIcon/>
            },
        ],
        "REPORTS": [
            {
                key:'/apreports',
                icon:<AccountBalanceOutlinedIcon/>
            }            
        ],
        "SETUP": [
            {
                key: '/vendors',
                icon: <LocalShippingOutlinedIcon/>
            },
            {
                key: '/vendortypes',
                icon: <TypeSpecimenOutlinedIcon/>
            },
            {
                key: '/contracts',
                icon: <LocalShippingOutlinedIcon/>
            }

        ]
    },
    "SYSTEM": {
        "MAIN": [
            {
                key:'/departments',
                icon:<ManageHistoryOutlinedIcon/>
            },
            {
                key:'/employees',
                icon:<BadgeIcon/>
            },
            {
                key:'/jobpositions',
                icon:<AttributionOutlinedIcon/>
            },
            {
                key:'/employee_types',
                icon:<AssignmentIndOutlinedIcon/>
            },
            {   
                key:'/exchangerates',
                icon:<CurrencyExchangeOutlinedIcon/>
            },
            {   
                key:'/taxes',
                icon:<AutoStoriesOutlinedIcon/>
            },
            {   
                key:'/paymentterms',
                icon:<PaymentsOutlinedIcon/>
            },
            {   
                key:'/activities',
                icon:<AccessibilityOutlinedIcon/>
            },
            {
                key:'/organization',
                icon:<BusinessOutlinedIcon/>
            },        
            {
                key:'/regions',
                icon:<SouthAmericaOutlinedIcon/>
            },
            {
                key:'/countries',
                icon:<AccountBalanceOutlinedIcon/>
            },        
            {   
                key:'/currencies',
                icon:<PriceChangeOutlinedIcon/>
            }
        ],
        "OPERATOR":[
            {
                key:'/users',
                icon:<GroupOutlinedIcon/>
             },
             {
                key:'/roles',
                icon:<AddTaskOutlinedIcon/>
             }   
        ],    
    }
}


interface SideDrawerProps extends WithTranslation, WithTheme<Theme> {
    
}

interface SideDrawerState {
    open: Map<string, boolean>;
}

class SideDrawer extends React.Component<SideDrawerProps, SideDrawerState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor (props : SideDrawerProps) {
        super (props);

        this.state = {
            open: new Map ([
                ["menu-invoices", false]
            ])
        };
    }

    handleNavigate = (to: string) => {
        const app = this.context;

        app.setState ({
            search:''
        }, () => {
            app.handleNavigate (to);
        });
    }

    handleOpen = (key:string) => {
        this.setState (prev => {
            const open = new Map (prev.open);

            open.set (key, !open.get(key));

            return ({
                open:open
            });
        });
    }

    handlePin = () => {
        const app = this.context;

        app.setState (prev => ({
            pin: !prev.pin
        }));
    }

    public render () {
        const {t} = this.props;
        const app = this.context;

        const menu = menus[app.drawer];

        return (
            <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      position:'relative',
                      pointerEvents: app.open ? 'auto':'none',
                      '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position:'absolute',
                      }
                    }}
                    variant="persistent"
                    anchor="left"
                    SlideProps={{direction:app.lang !== 'ar' ? 'right':'left'}}
                    open={app.open}
            >
                <DrawerHeader />
                <Divider />
                <List>
                    <ListItem sx={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                        <IconButton aria-label="pin" onClick={this.handlePin} sx={{transform:`rotateZ(${app.pin ? 45:90}deg)`}}> 
                            <PushPinOutlinedIcon />
                        </IconButton>
                    </ListItem>                         
                    {
                        (menu && Object.entries (menu).map ((entry) => {
                            const [key, value] = entry;

                            return  (
                                <React.Fragment key={`section-${key}`}>
                                    <ListSubheader>
                                        <ListItemText>{t(key)}</ListItemText>
                                    </ListSubheader>
                                    {value.map((location:any) => {
                                        if (location.sub) {
                                            return (
                                            <React.Fragment key={`menu-${location.key}`}>
                                            <ListItem button key={location.key} onClick={() => this.handleOpen (location.key)}>
                                                <ListItemIcon >
                                                {location.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(location.key)} />
                                                {this.state.open.get(location.key) ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.open.get(location.key)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        location.sub.map ((subloc:any) => (
                                                            <ListItem button sx={{pl:4}} key={subloc.key} onClick={() => this.handleNavigate (subloc.key)}>
                                                                <ListItemIcon >
                                                                {subloc.icon}
                                                                </ListItemIcon>
                                                                <ListItemText primary={t(subloc.key)} />
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </Collapse>
                                            </React.Fragment>
                                            );
                                        }
                                        else {
                                            return (<ListItem button key={location.key} onClick={() => this.handleNavigate (location.key)}>
                                                <ListItemIcon >
                                                {location.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(location.key)} />
                                            </ListItem>);
                                        }
                                    }
                                    )}                                    
                                </React.Fragment>
                            );
                        }))
                    }
                </List>
            </Drawer>        
        );
    }
}

export default withTranslation()(withTheme<Theme, React.JSXElementConstructor<SideDrawerProps>> (SideDrawer));