import { Stack, Box, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

export const ContainerBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    margin: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing:border-box;
`;

export const PageStack = styled(Stack)`
    min-width: 1024px;
    display: inline-flex;
    width: 100%;
`;

export const Export = styled('a')`
    display:none
`;


export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Fade}
    TransitionProps={{ timeout: 300 }}
    />
      ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

export const H3 = styled('h3')`
    color:#1975d2
`;

