import React from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import {withTheme, WithTheme} from '@mui/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


interface TablePaginationActionsProps extends  WithTheme<Theme> {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
}

class TablePaginationActions extends React.Component<TablePaginationActionsProps>  {

    constructor (props:TablePaginationActionsProps) {
        super (props);
    }

    private handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const {onPageChange} = this.props;

        onPageChange(event, 0);
    };
  
    private handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {onPageChange, page} = this.props;

        onPageChange(event, page - 1);
    };
  
    private handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {onPageChange, page} = this.props;

        onPageChange(event, page + 1);
    };
  
    private handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {onPageChange, count, rowsPerPage} = this.props;

          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    public render () {
        const { count, page, rowsPerPage, theme } = this.props;

        return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
            >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
            >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
            >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
            >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
        );
    }
  }

  export default withTheme<Theme, React.JSXElementConstructor<TablePaginationActionsProps>>(TablePaginationActions);