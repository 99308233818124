import React from 'react';
import {Box, Drawer, Theme, styled } from '@mui/material';
import { withTheme, WithTheme } from '@mui/styles';
import {AppContext} from '../AppContext';
import { withTranslation, WithTranslation } from 'react-i18next';
import {zIndex} from '../common/zindex';


export const StyledBox = styled (Box)`
    width:780px;
    height:100%;
    display:flex;
    flex-diretion: column;
`;
 

interface HelpDrawerProps extends WithTranslation, WithTheme<Theme> {
     
}

class HelpDrawer extends React.Component<HelpDrawerProps> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    public render () {
        const app = this.context;
        const {theme} = this.props;

        return (
            <div>
                <Drawer sx={{zIndex: theme.zIndex.drawer + zIndex.helpDrawer}} 
                    anchor={theme.direction === 'ltr' ? "right":"left"} 
                    SlideProps={{direction:app.lang !== 'ar' ? 'left':'right'}}
                    open={app.isHelpOpen} 
                    onClose={() => app.handleHelpDrawer(false)}>
                    <StyledBox role="presentation">
                    {
                        app.help
                    }
                    </StyledBox>
                </Drawer>
            </div>
        );
    }
}

export default withTranslation()(withTheme<Theme, React.JSXElementConstructor<HelpDrawerProps>>(HelpDrawer));
