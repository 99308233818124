import React, {useEffect} from 'react';
import {Box, Snackbar, CssBaseline, Alert} from '@mui/material';
import {Routes, Route, useNavigate} from "react-router-dom";
import {AppContext} from '../AppContext';
import { withTheme, WithTheme } from "@mui/styles";
import { styled } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, WithRouter } from "../common/router";
import TopBar from '../components/TopBar';
import SideDrawer, { drawerWidth, DrawerHeader } from '../components/SideDrawer';
import ModulesDrawer from '../components/ModulesDrawer';
import HelpDrawer from '../help/HelpDrawer';
import SignIn from './SignIn';
import SelectOrganization from './SelectOrganization';
import Home from './Home';
import ChequesDetailsView from './ChequesDetailsView';
import CompanyChequesDetailsView from './CompanyChequesDetailsView';


const BusinessAccounts = React.lazy(() => import('./BusinessAccounts'));
const BusinessAccount = React.lazy(() => import('./BusinessAccount'));
const BusinessOrganization = React.lazy(() => import('./BusinessOrganization'));
const SignUp = React.lazy(() => import('./SignUp'));
const Forgot = React.lazy(() => import('./Forgot'));
const TermsOfService = React.lazy(() => import('./TermsOfService'));
const Reset = React.lazy(() => import('./Reset'));
const Verify = React.lazy(() => import('./Verify'));
const Users = React.lazy(() => import('./Users'));
const User = React.lazy(() => import('./User'));
const Roles = React.lazy(() => import('./Roles'));
const Role = React.lazy(() => import('./Role'));
const CustomerTypes = React.lazy(() => import('./CustomerTypes'));
const CustomerType = React.lazy(() => import('./CustomerType'));
const PrintCustomerTypes = React.lazy(() => import('../reports/PrintCustomerTypes'));
const VendorTypes = React.lazy(() => import('./VendorTypes'));
const VendorType = React.lazy(() => import('./VendorType'));
const PrintVendorTypes = React.lazy(() => import('../reports/PrintVendorTypes'));
const WarehouseTypes = React.lazy(() => import('./WarehouseTypes'));
const WarehouseType = React.lazy(() => import('./WarehouseType'));
const TransactionTypes = React.lazy(() => import('./TransactionTypes'));
const TransactionType = React.lazy(() => import('./TransactionType'));
const UnitTypes = React.lazy(() => import('./UnitTypes'));
const UnitType = React.lazy(() => import('./UnitType'));
const PrintUnitTypes = React.lazy(() => import('../reports/PrintUnitTypes'));
const PrintProducts = React.lazy(() => import('../reports/PrintProducts'));
const PrintWarehouses = React.lazy(() => import('../reports/PrintWarehouses'));
const PrintCategories = React.lazy(() => import('../reports/PrintCategories'));
const SubJournals = React.lazy(() => import('./SubJournals'));
const SubJournal = React.lazy(() => import('./SubJournal'));
const Banks = React.lazy(() => import('./Banks'));
const Bank = React.lazy(() => import('./Bank'));
const PrintBanks = React.lazy(() => import('../reports/PrintBanks'));
const Vendors = React.lazy(() => import('./Vendors'));
const Vendor = React.lazy(() => import('./Vendor'));
const PrintVendors = React.lazy(() => import('../reports/PrintVendors'));
const Customers = React.lazy(() => import('./Customers'));
const Customer = React.lazy(() => import('./Customer'));
const Warehouses = React.lazy(() => import('./Warehouses'));
const Warehouse = React.lazy(() => import('./Warehouse'));
const Categories = React.lazy(() => import('./Categories'));
const Category = React.lazy(() => import('./Category'));
const Promotions = React.lazy(() => import('./Promotions'));
const Promotion = React.lazy(() => import('./Promotion'));
const PrintPromotions = React.lazy(() => import('../reports/PrintPromotions'));
const PrintRoles = React.lazy(() => import('../reports/PrintRoles'));
const Currencies = React.lazy(() => import('./Currencies'));
const Currency = React.lazy(() => import('./Currency'));
const CostCenters = React.lazy(() => import('./CostCenters'));
const CostCenter = React.lazy(() => import('./CostCenter'));
const Accounts = React.lazy(() => import('./Accounts'));
const Account = React.lazy(() => import('./Account'));
const Employees = React.lazy(() => import('./Employees'));
const Employee = React.lazy(() => import('./Employee'));
const PrintEmployees = React.lazy(() => import('../reports/PrintEmployees'));
const Regions = React.lazy(() => import('./Regions'));
const Region = React.lazy(() => import('./Region'));
const PrintRegions = React.lazy(() => import('../reports/PrintRegions'));
const BankAccounts = React.lazy(() => import('./BankAccounts'));
const BankAccount = React.lazy(() => import('./BankAccount'));
const PrintBankAccounts = React.lazy(() => import('../reports/PrintBankAccounts'));
const CashBoxes = React.lazy(() => import('./CashBoxes'));
const PrintCashBoxes = React.lazy(() => import('../reports/PrintCashBoxes'));
const CashBox = React.lazy(() => import('./CashBox'));
const Properties = React.lazy(() => import('./Properties'));
const Property = React.lazy(() => import('./Property'));
const Products = React.lazy(() => import('./Products'));
const Product = React.lazy(() => import('./Product'));
const ProductView = React.lazy(() => import('./ProductView'));
const PrintCurrencies = React.lazy(() => import('../reports/PrintCurrencies'));
const ExchangeRates = React.lazy(() => import('./ExchangeRates'));
const ExchangeRate = React.lazy(() => import('./ExchangeRate'));
const ReportView = React.lazy(() => import('./ReportView'));
const Profile = React.lazy(() => import('./Profile'));
const Countries = React.lazy(() => import('./Countries'));
const Country = React.lazy(() => import('./Country'));
const PrintCountries = React.lazy(() => import('../reports/PrintCountries'));
const Governerate = React.lazy(() => import('./Governerate'));
const City = React.lazy(() => import('./City'));
const Organization = React.lazy(() => import('./Organization'));
const Branch = React.lazy(() => import('./Branch'));
const Department = React.lazy(() => import('./Department'));
const Departments = React.lazy(() => import('./Departments'));
const PrintDepartments = React.lazy(() => import('../reports/PrintDepartments'));
const JobPositions = React.lazy(() => import('./JobPositions'));
const JobPosition = React.lazy(() => import('./JobPosition'));
const PrintJobPositions = React.lazy(() => import('../reports/PrintJobPositions'));
const Employee_Type = React.lazy(() => import('./EmployeeType'));
const Employee_Types = React.lazy(() => import('./EmployeeTypes'));
const PrintEmployeeTypes = React.lazy(() => import('../reports/PrintEmployeeTypes'));
const Taxes = React.lazy(() => import('./Taxes'));
const Tax = React.lazy(() => import('./Tax'));
const PrintTaxes = React.lazy(() => import('../reports/PrintTaxes'));
const PrintCustomers = React.lazy(() => import('../reports/PrintCustomers'));
const PrintAccounts = React.lazy(() => import('../reports/PrintAccounts'));
const PrintCostCenters = React.lazy(() => import('../reports/PrintCostCenters'));
const PrintAnalysisCodes = React.lazy(() => import('../reports/PrintAnalysisCodes'));
const PrintSubJournals = React.lazy(() => import('../reports/PrintSubJournals'));
const PrintProperties = React.lazy(() => import('../reports/PrintProperties'));
const PrintWarehouseTypes = React.lazy(() => import('../reports/PrintWarehouseTypes'));
const PrintTransactionTypes = React.lazy(() => import('../reports/PrintTransactionTypes'));
const PaymentTerms = React.lazy(() => import('./PaymentTerms'));
const PaymentTerm = React.lazy(() => import('./PaymentTerm'));
const PrintPaymentTerms = React.lazy(() => import('../reports/PrintPaymentTerms'));
const Salesteams = React.lazy(() => import('./Salesteams'));
const Salesteam = React.lazy(() => import('./Salesteam'));
const PrintSalesteams = React.lazy(() => import('../reports/PrintSalesteams'));
const Activities = React.lazy(() => import('./Activities'));
const Activity = React.lazy(() => import('./Activity'));
const PrintActivities = React.lazy(() => import('../reports/PrintActivities'));
const DeferredRevenues = React.lazy(() => import('./DeferredRevenues'));
const DeferredRevenue = React.lazy(() => import('./DeferredRevenue'));
const DeferredExpenses = React.lazy(() => import('./DeferredExpenses'));
const DeferredExpense = React.lazy(() => import('./DeferredExpense'));
const PrintAssets = React.lazy(() => import('../reports/PrintAssets'));
const PrintDeferredRevenues = React.lazy(() => import('../reports/PrintDeferredRevenues'));
const PrintDeferredExpenses = React.lazy(() => import('../reports/PrintDeferredExpenses'));
const Assets = React.lazy(() => import('./AssetsDepreciation'));
const Asset = React.lazy(() => import('./AssetDepreciation'));
const Pricelists = React.lazy(() => import('./Pricelists'));
const Pricelist = React.lazy(() => import('./Pricelist'));
const PrintPricelists = React.lazy(() => import('../reports/PrintPricelists'));
const CostLists = React.lazy(() => import('./CostLists'));
const CostList = React.lazy(() => import('./CostList'));
const PrintCostLists = React.lazy(() => import('../reports/PrintCostLists'));
const Quotations = React.lazy(() => import('./Quotations'));
const Quotation = React.lazy(() => import('./Quotation'));
const SalesOrders = React.lazy(() => import('./SalesOrders'));
const Sales_Order = React.lazy(() => import('./SalesOrder'));
const SalesInvoices = React.lazy(() => import('./SalesInvoices'));
const SalesInvoice = React.lazy(() => import('./SalesInvoice'));
const Purchase_Orders = React.lazy(() => import('./PurchaseOrders'));
const Purchase_Order = React.lazy(() => import('./PurchaseOrder'));
const Purchase_Invoices = React.lazy(() => import('./PurchaseInvoices'));
const Purchase_Invoice = React.lazy(() => import('./PurchaseInvoice'));
const Purchase_Quotations = React.lazy(() => import('./PurchaseQuotations'));
const Purchase_Quotation = React.lazy(() => import('./PurchaseQuotation'));
const SalesReturns = React.lazy(() => import('./SalesReturns'));
const SalesReturn = React.lazy(() => import('./SalesReturn'));
const Purchase_Return = React.lazy(() => import('./PurchaseReturn'));
const Purchase_Returns = React.lazy(() => import('./PurchaseReturns'));
const Delivery_Invoices = React.lazy(() => import('./DeliveryInvoices'));
const Delivery_Invoice = React.lazy(() => import('./DeliveryInvoice'));
const Replenishments = React.lazy(() => import('./Replenishments'));
const Replenishment = React.lazy(() => import('./Replenishment'));
const Issues = React.lazy(() => import('./Issues'));
const Issued = React.lazy(() => import('./Issued'));
const InventoryAdjustments = React.lazy(() => import('./InventoryAdjustments'));
const InventoryAdjustment = React.lazy(() => import('./InventoryAdjustment'));
const Adjust_Costs = React.lazy(() => import('./AdjustCosts'));
const Adjust_Cost = React.lazy(() => import('./AdjustCost'));
const WarehouseView = React.lazy(() => import('./WarehouseView'));
const WarehouseProductView = React.lazy(() => import('./WarehouseProductView'));
const Receive_Cashs = React.lazy(() => import('./Receive_Cashs'));
const Receive_Cash = React.lazy(() => import('./ReceiveCash'));
const Payout_Cashs = React.lazy(() => import('./Payout_Cashs'));
const Payout_Cash = React.lazy(() => import('./Payout_Cash'));
const Custodies = React.lazy(() => import('./Custodies'));
const Custody = React.lazy(() => import('./Custody'));
const Expenses = React.lazy(() => import('./Expenses'));
const Expense = React.lazy(() => import('./Expense'));
const PrintExpenses = React.lazy(() => import('../reports/PrintExpenses'));
const Receive_Cheques = React.lazy(() => import('./ReceiveCheques'));
const Receive_Cheque = React.lazy(() => import('./ReceiveCheque'));
const Payout_Cheques = React.lazy(() => import('./PayoutCheques'));
const Payout_Cheque = React.lazy(() => import('./PayoutCheque'));
const Custody_Expenses = React.lazy(() => import('./CustodyExpenses'));
const Custody_Expense = React.lazy(() => import('./CustodyExpense'));
const Payout_CoCheques = React.lazy(() => import('./PayoutCoCheques'));
const Payout_CoCheque = React.lazy(() => import('./PayoutCoCheque'));
const Debit_Notes = React.lazy(() => import('./DebitNotes'));
const DebitNote = React.lazy(() => import('./DebitNote'));
const Credit_Notes = React.lazy(() => import('./CreditNotes'));
const Credit_Note = React.lazy(() => import('./CreditNote'));
const Bank_Receive_Cheques = React.lazy(() => import('./BankReceiveCheques'));
const Bank_Receive_Cheque = React.lazy(() => import('./BankReceiveCheque'));
const Collected_Debit_Cheques = React.lazy(() => import('./CollectedDebitCheques'));
const Collected_Debit_Cheque = React.lazy(() => import('./CollectedDebitCheque'));
const Collected_Credit_Cheques = React.lazy(() => import('./CollectedCreditCheques'));
const Collected_Credit_Cheque = React.lazy(() => import('./CollectedCreditCheque'));
const Cheques = React.lazy(() => import('./Cheques'));
const CompanyCheques = React.lazy(() => import('./CompanyCheques'));
// const Receive_Cheques_Status = React.lazy(() => import('./Receive_Cheques_Status'));
// const Receive_Cheque_Status = React.lazy(() => import('./Receive_Cheque_Status'));
const Journals = React.lazy(() => import('./Journals'));
const Journal = React.lazy(() => import( './Journal'));
const AnalysisCodes = React.lazy(() => import( './AnalysisCodes'));
const AnalysisCode = React.lazy(() => import( './AnalysisCode'));
const Bank_Balances = React.lazy(() => import( './BankBalances'));
const Bank_Balance = React.lazy(() => import( './BankBalance'));
const CashBox_Balances = React.lazy(() => import( './CashBoxBalances'));
const CashBox_Balance = React.lazy(() => import( './CashBoxBalance'));
const CustomerBalances = React.lazy(() => import( './CustomerBalances'));
const CustomerBalance = React.lazy(() => import( './CustomerBalance'));
const Vendor_Balances = React.lazy(() => import( './VendorBalances'));
const Vendor_Balance = React.lazy(() => import( './VendorBalance'));
const Custody_Employee_Balances = React.lazy(() => import( './CustodyEmployeeBalances'));
const Custody_Employee_Balance = React.lazy(() => import( './CustodyEmployeeBalance'));
const AccountBalances = React.lazy(() => import( './AccountBalances'));
const AccountBalance = React.lazy(() => import( './AccountBalance'));
const CostCenterBalances = React.lazy(() => import( './CostCenterBalances'));
const CostCenterBalance = React.lazy(() => import( './CostCenterBalance'));
const Import = React.lazy(() => import( './Import'));
const FinancialYears = React.lazy(() => import( './FinancialYears'));
const FinancialYear = React.lazy(() => import( './FinancialYear'));
const CurrenciesRevaluation = React.lazy(() => import( './CurrenciesRevaluation'));
const CurrencyRevaluation = React.lazy(() => import( './CurrencyRevaluation'));
const BalanceSheets = React.lazy(() => import( './BalanceSheets'));
const BalanceSheet = React.lazy(() => import( './BalanceSheet'));
const IncomeStatements = React.lazy(() => import( './IncomeStatements'));
const IncomeStatement = React.lazy(() => import( './IncomeStatement'));
const Batches = React.lazy(() => import( './Batches'));
const Batch = React.lazy(() => import( './Batch'));
const PrintBatches = React.lazy(() => import( '../reports/PrintBatches'));
const Customer_Credit_Notes = React.lazy(() => import( './CustomerCreditNotes'));
const Customer_Credit_Note = React.lazy(() => import( './CustomerCreditNote'));
const Customer_Debit_Notes = React.lazy(() => import( './CustomerDebitNotes'));
const Customer_Debit_Note = React.lazy(() => import( './CustomerDebitNote'));
const Vendor_Credit_Notes = React.lazy(() => import( './VendorCreditNotes'));
const Vendor_Credit_Note = React.lazy(() => import( './VendorCreditNote'));
const Vendor_Debit_Notes = React.lazy(() => import( './VendorDebitNotes'));
const Vendor_Debit_Note = React.lazy(() => import( './VendorDebitNote'));
const PrintAccountBalances = React.lazy(() => import( '../reports/PrintAccountBalances'));
const PrintCostCenterBalances = React.lazy(() => import( '../reports/PrintCostCenterBalances'));
const PostJournals = React.lazy(() => import( './PostJournals'));
const PrintBalanceSheets = React.lazy(() => import( '../reports/PrintBalanceSheets'));
const PrintIncomeStatements = React.lazy(() => import( '../reports/PrintIncomeStatements'));
const PrintTrialBalance = React.lazy(() => import( '../reports/PrintTrialBalance'));
const PrintAccountLedger = React.lazy(() => import( '../reports/PrintAccountLedger'));
const PrintJournals = React.lazy(() => import( '../reports/PrintJournals'));
const PrintAccJournals = React.lazy(() => import( '../reports/PrintAccJournals'));
const PrintCostCenterLedger = React.lazy(() => import( '../reports/PrintCostCenterLedger'));
const PrintCashReceive = React.lazy(() => import( '../reports/PrintCashReceive'));
const PrintCashPayout = React.lazy(() => import( '../reports/PrintCashPayout'));
const PrintCashTrxBalance = React.lazy(() => import( '../reports/PrintCashTrxBalance'));
const PrintCashCustody = React.lazy(() => import( '../reports/PrintCashCustody'));
const PrintCashCustodyTrx = React.lazy(() => import( '../reports/PrintCashCustodyTrx'));
const PrintChequesReceive = React.lazy(() => import( '../reports/PrintChequesReceive'));
const PrintChequesPayout = React.lazy(() => import( '../reports/PrintChequesPayout'));
const PrintChequesCompanyPayout = React.lazy(() => import( '../reports/PrintChequesCompanyPayout'));
const PrintBankReceiveCheques = React.lazy(() => import( '../reports/PrintBankReceiveCheques'));
const PrintDebitNote = React.lazy(() => import( '../reports/PrintDebitNote'));
const PrintCreditNote = React.lazy(() => import( '../reports/PrintCreditNote'));
const PrintCashboxBalance = React.lazy(() => import( '../reports/PrintCashboxBalance'));
const PrintBankAccountBalance = React.lazy(() => import( '../reports/PrintBankAccountBalance'));
const PrintChequeStatus = React.lazy(() => import( '../reports/PrintChequeStatus'));
const PrintCompanyChequeStatus = React.lazy(() => import( '../reports/PrintCompanyChequeStatus'));
const PrintCustodyChequeStatus = React.lazy(() => import( '../reports/PrintCustodyChequeStatus'));
const PrintQuotations = React.lazy(() => import( '../reports/PrintQuotations'));
const PrintPurchaseOrders = React.lazy(() => import( '../reports/PrintPurchaseOrders'));
const PrintPurchaseInvoices = React.lazy(() => import( '../reports/PrintPurchaseInvoices'));
const PrintPurchaseReturns = React.lazy(() => import( '../reports/PrintPurchaseReturns'));
const PrintPurchaseAnalysis = React.lazy(() => import( '../reports/PrintPurchaseAnalysis'));
const PrintVendorAnalysis = React.lazy(() => import( '../reports/PrintVendorAnalysis'));
const PrintVendorStatement = React.lazy(() => import( '../reports/PrintVendorStatement'));
const PrintVendorDebitNotes = React.lazy(() => import( '../reports/PrintVendorDebitNotes'));
const PrintVendorCreditNotes = React.lazy(() => import( '../reports/PrintVendorCreditNotes'));
const GLReports = React.lazy(() => import( './GLReports'));
const SCReports = React.lazy(() => import( './SCReports'));
const CBReports = React.lazy(() => import( './CBReports'));
const APReports = React.lazy(() => import( './APReports'));
const PrintCostCenter_Transactions = React.lazy(() => import( '../reports/PrintCostCenter_Transactions'));
const Contracts = React.lazy(() => import( './Contracts'));
const Contract = React.lazy(() => import( './Contract'));
const Work_Orders = React.lazy(() => import( './WorkOrders'));
const Work_Order = React.lazy(() => import( './WorkOrder'));
const PrintAccountAnalysis = React.lazy(() => import( '../reports/PrintAccountAnalysis'));
const PrintItemCard = React.lazy(() => import( '../reports/PrintItemCard'));
const PrintItemsbalances = React.lazy(() => import( '../reports/PrintItemsbalances'));
const PrintItemsbalancesvalues = React.lazy(() => import( '../reports/PrintItemsbalancesvalues'));
const PrintReplenishments = React.lazy(() => import( '../reports/PrintReplenishments'));
const PrintTransactions = React.lazy(() => import( '../reports/PrintTransactions'));
const PrintIssues = React.lazy(() => import( '../reports/PrintIssues'));
const PrintSerials = React.lazy(() => import( '../reports/PrintSerials'));
const PrintExpiry_Dates = React.lazy(() => import( '../reports/PrintExpiry_Dates'));
const PrintProductBatches = React.lazy(() => import( '../reports/PrintProductBatches'));


type StyledMainProps = {
  open: boolean;
  lang: string;
}

const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'lang'})<StyledMainProps>(
  ({ theme, open}) => ({
    flexGrow: 1,
    minHeight: '100vh',
    display:'flex',
    flexDirection:'column',
    pl:0,
    pr:0,
    paddingTop: theme.spacing(3),
    ...{
      marginLeft: `-${drawerWidth}px`,
      marginRight: 0,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    },
  }),
);

const Content = styled('div')`
  position: absolute;
  overflow: auto;
  top:0;
  left:0;
  right:0;
  bottom:0;
  flex-direction:column;
  display:flex;
`;

type RedirectProps = {
  to: string;
}; 

const Redirect = ({ to }: RedirectProps) =>  {
  const navigate = useNavigate();

  useEffect(() => {
      navigate(to);
  });

  return null;
}

interface MainProps extends WithTranslation, WithTheme<any>, WithRouter {
   
}

const hideSidebar = [
  "/",
  "/signup",
  "/termsofservice",
  "/forgot",
  "/reset",
  "/verify",
  "/selectorganization"
];

class Main extends React.Component<MainProps> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    public render () {
        const app = this.context;
        const {theme, location} = this.props;

        return (
        <Box sx={{display:'flex', direction:theme.direction}}>
          <CssBaseline />
            <TopBar />
            <Snackbar
              anchorOrigin={{ horizontal:theme.direction === 'rtl' ? 'left':'right', vertical:'top' }}
              sx={{marginTop:10}}
              open={app.show}
              onClose={app.handleMessageClose}
              autoHideDuration={6000} >
                <Alert onClose={app.handleMessageClose} severity={app.severity} sx={{ width: '100%' }}>
                  { app.message }
                </Alert>
            </Snackbar>      
            {
              (app.user_id &&  !hideSidebar.includes (location.pathname) &&
                <ModulesDrawer /> 
              )
            }
            <SideDrawer />
            <HelpDrawer />
            <StyledMain open={app.open} lang={app.lang}>
                <DrawerHeader />
                <Box sx={{flexGrow:1, position:'relative'}}>
                  <Content>
                    <Routes>
                      <Route path="/" element={<SignIn />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/termsofservice" element={<TermsOfService />} />
                      <Route path="/forgot" element={<Forgot />} />
                      <Route path="/reset" element={<Reset />} />
                      <Route path="/verify" element={<Verify />} />
                      {
                        (app.organization_id === 0 && app.user_id &&
                          <React.Fragment>
                            <Route path="/selectorganization" element={<SelectOrganization />} />
                            <Route path="/businessaccounts" element={<BusinessAccounts />} />
                            <Route path="/businessaccount" element={<BusinessAccount />} />
                            <Route path="/businessorganization" element={<BusinessOrganization />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/user" element={<User />} />
                            <Route path="/profile" element={<Profile />} />
                          </React.Fragment>
                        )
                      }
                      { 
                        (app.organization_id !== 0 && app.user_id && 
                          <React.Fragment>
                            <Route path="/selectorganization" element={<SelectOrganization />} />
                            <Route path="/businessaccounts" element={<BusinessAccounts />} />
                            <Route path="/businessaccount" element={<BusinessAccount />} />
                            <Route path="/businessorganization" element={<BusinessOrganization />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/customertypes" element={<CustomerTypes />} />
                            <Route path="/customertype" element={<CustomerType />} />
                            <Route path="/printcustomertypes" element={<PrintCustomerTypes />} />
                            <Route path="/vendortypes" element={<VendorTypes />} />
                            <Route path="/vendortype" element={<VendorType />} />
                            <Route path="/printvendortypes" element={<PrintVendorTypes />} />
                            <Route path="/warehousetypes" element={<WarehouseTypes />} />
                            <Route path="/warehousetype" element={<WarehouseType />} />
                            <Route path="/printwarehousetypes" element={<PrintWarehouseTypes />} />
                            <Route path="/transactiontypes" element={<TransactionTypes />} />
                            <Route path="/transactiontype" element={<TransactionType />} />
                            <Route path="/printtransactiontypes" element={<PrintTransactionTypes />} />
                            <Route path="/unittypes" element={<UnitTypes />} />
                            <Route path="/unittype" element={<UnitType />} />
                            <Route path="/printunittypes" element={<PrintUnitTypes />} />
                            <Route path="/subjournals" element={<SubJournals />} />
                            <Route path="/subjournal" element={<SubJournal />} />
                            <Route path="/countries" element={<Countries />} />
                            <Route path="/country" element={<Country />} />
                            <Route path="/printcountries" element={<PrintCountries />} />
                            <Route path="/governerate" element={<Governerate />} />
                            <Route path="/city" element={<City />} />
                            <Route path="/banks" element={<Banks />} />
                            <Route path="/bank" element={<Bank />} />
                            <Route path="/printbanks" element={<PrintBanks />} />
                            <Route path="/vendors" element={<Vendors />} />
                            <Route path="/vendor" element={<Vendor />} />
                            <Route path="/printvendors" element={<PrintVendors />} />
                            <Route path="/customers" element={<Customers />} />
                            <Route path="/customer" element={<Customer />} />
                            <Route path="/printcustomers" element={<PrintCustomers />} />
                            <Route path="/printaccounts" element={<PrintAccounts />} />
                            <Route path="/printproducts" element={<PrintProducts />} />
                            <Route path="/printwarehouses" element={<PrintWarehouses />} />
                            <Route path="/printcategories" element={<PrintCategories />} />
                            <Route path="/printcostcenters" element={<PrintCostCenters />} />
                            <Route path="/printcashreceive" element={<PrintCashReceive />} />
                            <Route path="/printcashpayout" element={<PrintCashPayout />} />
                            <Route path="/printcashtrxbalance" element={<PrintCashTrxBalance />} />
                            <Route path="/printcashcustody" element={<PrintCashCustody />} />
                            <Route path="/printcashcustodytrx" element={<PrintCashCustodyTrx />} />
                            <Route path="/printchequesreceive" element={<PrintChequesReceive />} />
                            <Route path="/printchequespayout" element={<PrintChequesPayout />} />
                            <Route path="/printchequescompanypayout" element={<PrintChequesCompanyPayout />} />
                            <Route path="/printbankreceivecheques" element={<PrintBankReceiveCheques />} />
                            <Route path="/printdebitnote" element={<PrintDebitNote />} />
                            <Route path="/printcreditnote" element={<PrintCreditNote />} />
                            <Route path="/printcashboxbalance" element={<PrintCashboxBalance />} />
                            <Route path="/printbankaccountbalance" element={<PrintBankAccountBalance />} />
                            <Route path="/printchequestatus" element={<PrintChequeStatus />} />
                            <Route path="/printcompanychequestatus" element={<PrintCompanyChequeStatus />} />
                            <Route path="/printcustodychequestatus" element={<PrintCustodyChequeStatus />} />
                            <Route path="/printquotations" element={<PrintQuotations/>} />
                            <Route path="/printpurchaseorders" element={<PrintPurchaseOrders/>} />
                            <Route path="/printpurchaseinvoices" element={<PrintPurchaseInvoices/>} />
                            <Route path="/printpurchasereturns" element={<PrintPurchaseReturns/>} />
                            <Route path="/printpurchaseanalysis" element={<PrintPurchaseAnalysis/>} />
                            <Route path="/printvendoranalysis" element={<PrintVendorAnalysis/>} />
                            <Route path="/printvendorstatement" element={<PrintVendorStatement/>} />
                            <Route path="/printvendordebitnotes" element={<PrintVendorDebitNotes/>} />
                            <Route path="/printvendorcreditnotes" element={<PrintVendorCreditNotes/>} />
                            <Route path="/warehouses" element={<Warehouses />} />
                            <Route path="/warehouse" element={<Warehouse />} />
                            <Route path="/categories" element={<Categories />} />
                            <Route path="/category" element={<Category />} />
                            <Route path="/promotions" element={<Promotions />} />
                            <Route path="/promotion" element={<Promotion />} />
                            <Route path="/printpromotions" element={<PrintPromotions />} />
                            <Route path="/exchangerates" element={<ExchangeRates />} />
                            <Route path="/exchangerate" element={<ExchangeRate />} />
                            <Route path="/currencies" element={<Currencies />} />
                            <Route path="/currency" element={<Currency />} />
                            <Route path="/printcurrencies" element={<PrintCurrencies />} />
                            <Route path="/costcenters" element={<CostCenters />} />
                            <Route path="/costcenter" element={<CostCenter />} />
                            <Route path="/accounts" element={<Accounts />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/employees" element={<Employees />} />
                            <Route path="/employee" element={<Employee />} />
                            <Route path="/printemployees" element={<PrintEmployees />} />
                            <Route path="/regions" element={<Regions />} />
                            <Route path="/region" element={<Region />} />
                            <Route path="/printregions" element={<PrintRegions />} />
                            <Route path="/bankaccounts" element={<BankAccounts />} />
                            <Route path="/bankaccount" element={<BankAccount />} />
                            <Route path="/printbankaccounts" element={<PrintBankAccounts />} />
                            <Route path="/cashboxes" element={<CashBoxes />} />
                            <Route path="/cashbox" element={<CashBox />} />
                            <Route path="/printcashboxes" element={<PrintCashBoxes />} />
                            <Route path="/properties" element={<Properties />} />
                            <Route path="/property" element={<Property />} />
                            <Route path="/printproperties" element={<PrintProperties />} />
                            <Route path="/products" element={<Products />} />
                            <Route path="/product" element={<Product />} />
                            <Route path="/productview" element={<ProductView />} />
                            <Route path="/organization" element={<Organization />} />
                            <Route path="/branch" element={<Branch />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/user" element={<User />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route path="/role" element={<Role />} />
                            <Route path="/departments" element={<Departments />} />
                            <Route path="/department" element={<Department />} />
                            <Route path="/printdepartments" element={<PrintDepartments />} />
                            <Route path="/jobpositions" element={<JobPositions />} />
                            <Route path="/jobposition" element={<JobPosition />} />
                            <Route path="/printjobpositions" element={<PrintJobPositions />} />
                            <Route path="/employee_types" element={<Employee_Types />} />
                            <Route path="/employee_type" element={<Employee_Type />} />
                            <Route path="/printemployee_types" element={<PrintEmployeeTypes />} />
                            <Route path="/taxes" element={<Taxes />} />
                            <Route path="/tax" element={<Tax />} />
                            <Route path="/printtaxes" element={<PrintTaxes />} />
                            <Route path="/paymentterms" element={<PaymentTerms />} />
                            <Route path="/paymentterm" element={<PaymentTerm />} />
                            <Route path="/printpaymentterms" element={<PrintPaymentTerms />} />
                            <Route path="/printroles" element={<PrintRoles />} />
                            <Route path="/view" element={<ReportView />} />
                            <Route path="/printanalysiscodes" element={<PrintAnalysisCodes />} />
                            <Route path="/printsubjournals" element={<PrintSubJournals />} />
                            <Route path="/salesteams" element={<Salesteams />} />
                            <Route path="/salesteam" element={<Salesteam />} />
                            <Route path="/printsalesteams" element={<PrintSalesteams />} />
                            <Route path="/activities" element={<Activities />} />
                            <Route path="/activity" element={<Activity />} />
                            <Route path="/printactivities" element={<PrintActivities />} />
                            <Route path="/deferredrevenues" element={<DeferredRevenues />} />
                            <Route path="/deferredrevenue" element={<DeferredRevenue />} />
                            <Route path="/deferredexpenses" element={<DeferredExpenses />} />
                            <Route path="/deferredexpense" element={<DeferredExpense />} />
                            <Route path="/assetdepreciation" element={<Assets />} />
                            <Route path="/asset" element={<Asset />} />
                            <Route path="/pricelists" element={<Pricelists />} />
                            <Route path="/pricelist" element={<Pricelist />} />
                            <Route path="/printpricelists" element={<PrintPricelists />} />
                            <Route path="/costlists" element={<CostLists />} />
                            <Route path="/costlist" element={<CostList />} />
                            <Route path="/printcostlists" element={<PrintCostLists />} />
                            <Route path="/printassets" element={<PrintAssets />} />
                            <Route path="/printdeferredrevenues" element={<PrintDeferredRevenues />} />
                            <Route path="/printdeferredexpenses" element={<PrintDeferredExpenses />} />
                            <Route path="/quotations" element={<Quotations />} />
                            <Route path="/quotation" element={<Quotation />} />
                            <Route path="/salesorders" element={<SalesOrders />} />
                            <Route path="/sales_order" element={<Sales_Order />} />
                            <Route path="/salesinvoices" element={<SalesInvoices />} />
                            <Route path="/salesinvoice" element={<SalesInvoice />} />
                            <Route path="/purchase_orders" element={<Purchase_Orders />} />
                            <Route path="/purchase_order" element={<Purchase_Order />} />
                            <Route path="/purchase_invoices" element={<Purchase_Invoices />} />
                            <Route path="/purchase_invoice" element={<Purchase_Invoice />} />
                            <Route path="/purchase_quotations" element={<Purchase_Quotations />} />
                            <Route path="/purchase_quotation" element={<Purchase_Quotation />} />
                            <Route path="/sales_returns" element={<SalesReturns />} />
                            <Route path="/sales_return" element={<SalesReturn />} />
                            <Route path="/purchase_returns" element={<Purchase_Returns />} />
                            <Route path="/purchase_return" element={<Purchase_Return />} />
                            <Route path="/delivery_invoices" element={<Delivery_Invoices />} />
                            <Route path="/delivery_invoice" element={<Delivery_Invoice />} />
                            <Route path="/replenishments" element={<Replenishments />} />
                            <Route path="/replenishment" element={<Replenishment />} />
                            <Route path="/issues" element={<Issues />} />
                            <Route path="/issued" element={<Issued />} />
                            <Route path="/inventoryadjustments" element={<InventoryAdjustments />} />
                            <Route path="/inventoryadjustment" element={<InventoryAdjustment />} />
                            <Route path="/adjust_costs" element={<Adjust_Costs />} />
                            <Route path="/adjust_cost" element={<Adjust_Cost />} />
                            <Route path="/warehouseview" element={<WarehouseView />} />
                            <Route path="/warehouseproductview" element={<WarehouseProductView />} />
                            <Route path="/receive_cashs" element={<Receive_Cashs />} />
                            <Route path="/receive_cash_receipt" element={<Receive_Cash />} />
                            <Route path="/payout_cashs" element={<Payout_Cashs />} />
                            <Route path="/payout_cash_receipt" element={<Payout_Cash />} />
                            <Route path="/custodies" element={<Custodies />} />
                            <Route path="/custody" element={<Custody />} />
                            <Route path="/expenses" element={<Expenses />} />
                            <Route path="/expense" element={<Expense />} />
                            <Route path="/printexpenses" element={<PrintExpenses />} />
                            <Route path="/receive_cheques" element={<Receive_Cheques />} />
                            <Route path="/receive_cheque" element={<Receive_Cheque />} />
                            <Route path="/payout_cheques" element={<Payout_Cheques />} />
                            <Route path="/payout_cheque" element={<Payout_Cheque />} />
                            <Route path="/custody_expenses" element={<Custody_Expenses />} />
                            <Route path="/custody_expense" element={<Custody_Expense />} />
                            <Route path="/payout_cocheques" element={<Payout_CoCheques />} />
                            <Route path="/payout_cocheque" element={<Payout_CoCheque />} />
                            <Route path="/debit_notes" element={<Debit_Notes />} />
                            <Route path="/debit_note" element={<DebitNote />} />
                            <Route path="/credit_notes" element={<Credit_Notes />} />
                            <Route path="/credit_note" element={<Credit_Note />} />
                            <Route path="/bank_receive_cheques" element={<Bank_Receive_Cheques />} />
                            <Route path="/bank_receive_cheque" element={<Bank_Receive_Cheque />} />
                            <Route path="/collected_debit_cheque" element={<Collected_Debit_Cheque />} />
                            <Route path="/collected_debit_cheques" element={<Collected_Debit_Cheques />} />
                            <Route path="/collected_credit_cheque" element={<Collected_Credit_Cheque />} />
                            <Route path="/collected_credit_cheques" element={<Collected_Credit_Cheques />} />
                            <Route path="/cheques" element={<Cheques />} />
                            <Route path="/companycheques" element={<CompanyCheques />} />
                            <Route path="/chequesdetailsview" element={<ChequesDetailsView />} />
                            <Route path="/companychequesdetailsview" element={<CompanyChequesDetailsView />} />
                            {/* <Route path="/receive_cheques_status" element={<Receive_Cheques_Status />} />
                            <Route path="/receive_cheque_status" element={<Receive_Cheque_Status />} /> */}
                            <Route path="/journals" element={<Journals />} />
                            <Route path="/journal" element={<Journal />} />
                            <Route path="/analysiscodes" element={<AnalysisCodes />} />
                            <Route path="/analysiscode" element={<AnalysisCode />} />
                            <Route path="/bank_balances" element={<Bank_Balances />} />
                            <Route path="/bank_balance" element={<Bank_Balance />} />
                            <Route path="/cashbox_balances" element={<CashBox_Balances />} />
                            <Route path="/cashbox_balance" element={<CashBox_Balance />} />
                            <Route path="/customer_balances" element={<CustomerBalances />} />
                            <Route path="/customer_balance" element={<CustomerBalance />} />
                            <Route path="/vendor_balances" element={<Vendor_Balances />} />
                            <Route path="/vendor_balance" element={<Vendor_Balance />} />
                            <Route path="/custody_employee_balances" element={<Custody_Employee_Balances />} />
                            <Route path="/custody_employee_balance" element={<Custody_Employee_Balance />} />
                            <Route path="/accountbalances" element={<AccountBalances />} />
                            <Route path="/accountbalance" element={<AccountBalance />} />
                            <Route path="/costcenterbalances" element={<CostCenterBalances />} />
                            <Route path="/costcenterbalance" element={<CostCenterBalance />} />
                            <Route path="/import" element={<Import />} />
                            <Route path="/financialyears" element={<FinancialYears />} />
                            <Route path="/financialyear" element={<FinancialYear />} />
                            <Route path="/currenciesrevaluation" element={<CurrenciesRevaluation />} />
                            <Route path="/currencyrevaluation" element={<CurrencyRevaluation />} />
                            <Route path="/balancesheets" element={<BalanceSheets />} />
                            <Route path="/balancesheet" element={<BalanceSheet />} />
                            <Route path="/incomestatements" element={<IncomeStatements />} />
                            <Route path="/incomestatement" element={<IncomeStatement />} />
                            <Route path="/batches" element={<Batches />} />
                            <Route path="/batch" element={<Batch />} />
                            <Route path="/printbatches" element={<PrintBatches />} />
                            <Route path="/customercreditnotes" element={<Customer_Credit_Notes />} />
                            <Route path="/customercreditnote" element={<Customer_Credit_Note />} />
                            <Route path="/customerdebitnotes" element={<Customer_Debit_Notes />} />
                            <Route path="/customerdebitnote" element={<Customer_Debit_Note />} />
                            <Route path="/vendorcreditnotes" element={<Vendor_Credit_Notes />} />
                            <Route path="/vendorcreditnote" element={<Vendor_Credit_Note />} />
                            <Route path="/vendordebitnotes" element={<Vendor_Debit_Notes />} />
                            <Route path="/vendordebitnote" element={<Vendor_Debit_Note />} />
                            <Route path="/printtrialbalance" element={<PrintTrialBalance />} />
                            <Route path="/printaccountledger" element={<PrintAccountLedger />} />
                            <Route path="/printaccountbalances" element={<PrintAccountBalances />} />
                            <Route path="/postjournals" element={<PostJournals />} />
                            <Route path="/printjournals" element={<PrintJournals />} />
                            <Route path="/printbalancesheets" element={<PrintBalanceSheets />} />
                            <Route path="/printcostcentertransactions" element={<PrintCostCenter_Transactions />} />
                            <Route path="/printcostcenterbalances" element={<PrintCostCenterBalances />} />
                            <Route path="/printincomestatements" element={<PrintIncomeStatements />} />
                            <Route path="/printaccjournals" element={<PrintAccJournals />} />
                            <Route path="/printcostcenterledger" element={<PrintCostCenterLedger />} />
                            <Route path="/printaccountanalysis" element={<PrintAccountAnalysis />} />
                            <Route path="/glreports" element={<GLReports />} />
                            <Route path="/screports" element={<SCReports />} />
                            <Route path="/cbreports" element={<CBReports />} />
                            <Route path="/apreports" element={<APReports />} />
                            <Route path="/contracts" element={<Contracts />} />
                            <Route path="/contract" element={<Contract />} />
                            <Route path="/work_orders" element={<Work_Orders />} />
                            <Route path="/work_order" element={<Work_Order />} />
                            <Route path="/printitemcard" element={<PrintItemCard />} />
                            <Route path="/printitemsbalancesvalues" element={<PrintItemsbalancesvalues />} />
                            <Route path="/printitemsbalances" element={<PrintItemsbalances />} />
                            <Route path="/printreplenishments" element={<PrintReplenishments />} />
                            <Route path="/printtransactions" element={<PrintTransactions />} />
                            <Route path="/printissues" element={<PrintIssues />} />
                            <Route path="/printserials" element={<PrintSerials />} />
                            <Route path="/printexpiry_dates" element={<PrintExpiry_Dates />} />
                            <Route path="/printproductbatches" element={<PrintProductBatches />} />
                            
                          </React.Fragment>
                        )
                      }
                    <Route path="*" element={<Redirect to="/" />} />
                  </Routes>
                  </Content>
                </Box>
            </StyledMain>
        </Box>
        ); 
    }
}

export default withTranslation ()(withTheme(withRouter(Main)));